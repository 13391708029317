export class JsonRequestBuilder {
    req = null;


    init = () => {
        this.req = new JsonRequest();
        this.req.headers = new Headers();
        this.req.headers.append("Content-Type", "application/json");

        this.req.successCb = (data) => { };
        this.req.failureCb = (error) => { };
        this.req.url = null;
        this.req.requestInit = {};
        // set to JSON
        return this;
    }

    useMethod = (method) => {
        this.req.requestInit = { ...this.req.requestInit, method };
        return this;
    };
    withAuth = () => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        this.req.headers.append("Authorization", `Bearer ${auth.token}`);
        this.req.useAuth = true;
        return this;
    };

    withSuccessCb = (cb) => {
        this.req.successCb = cb;
        return this;
    };

    withFailureCb = (cb) => {
        this.req.failureCb = cb;
        return this;
    };

    withBody = (body) => {
        this.req.requestInit = { ...this.req.requestInit, body: JSON.stringify(body) };
        return this;
    };

    useLoading = (loading) => {
        this.req.useLoading = loading;
        return this;
    };

    withUrl = (url) => {
        this.req.url = url;
        return this;
    };

    get = () => {
        this.req.requestInit = { ...this.req.requestInit, headers: this.req.headers, redirect: "follow" };

        return this.req;
    }
};


export class JsonRequest {
    headers = null;
    method = null;
    requestInit = null;
    successCb = null;
    failureCb = null;
    useLoading = true;
    useAuth = true;
    url = null;
}
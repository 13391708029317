import { CheckSquareTwoTone, BorderOutlined } from "@ant-design/icons";

const ItemCheckBox = ({ isChecked, onClick, ...props }) => {
    if (isChecked) {
        return (
            <CheckSquareTwoTone
                onClick={onClick}
                style={{ cursor: "pointer", fontSize: "16px", backgroundColor: "transparent" }}
                {...props} />
        )
        // ...

    } else {
        return (
            <BorderOutlined
                onClick={onClick}
                style={{ cursor: "pointer", fontSize: "16px", backgroundColor: "transparent" }}
                {...props} />
        )
    }
};

export default ItemCheckBox;
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import SortIcon from "./icons/SortIcon";
import ItemCheckBox from "./ItemCheckBox";
import {formatNumber, isCpuSelectedStyle, truncateCell} from "../common/style-functions";

const CpuSelected = ({ isCpuSelected }) => {
    if (isCpuSelected) {
        return (
            <div className="w-100 d-flex align-items-center mt-2">
                <img
                    src="/assets/img/icon/cpu_picked.svg"
                    style={{
                        width: "65.62px",
                        height: "16px",
                    }}
                />
            </div>
        )
    }
};
const FavoriteIndicator = ({ isFavorite, onClick, ...props }) => {
    if (isFavorite) {
        return (
            <i
                className="fas fa-star star-fill"
                onClick={onClick}
                style={{ cursor: "pointer" }}
                {...props}
            ></i>
        )
    } else {
        return (
            <i
                className="far fa-star"
                onClick={onClick}
                style={{ cursor: "pointer" }}
                {...props}
            ></i>
        )
    }
};

const HorseTableRowView = ({
    item,
    horses,
    setShow,
    setNoteShow,
    setHorseEdit,
    allowHorseEdit,
    showFavoriteButton,
    showSelectCheckBox,
    handleFavoriteClick,
    onSelectSingleClick,
    isSelected,
    idFieldName,
    ...props
}) => {
    const [showGsrGrid, setShowGsrGrid] = useState(false);

    const handleShow = (value) => {
        setShowGsrGrid(showGsrGrid === value ? 0 : value);
    };

    const handleNoteShow = (value) => {
        setHorseEdit(item);
        setNoteShow(true);
    };

    // console.log('item.geneticStrengthRatings', item.geneticStrengthRatings);

    const [sort, setSort] = useState(true);
    const [gsrSort, setGsrSort] = useState("");
    const [gsrList, setGSRList] = useState(item.gsrGridRows);

    const getGsrDirt = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.dirt - b.dirt));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.dirt - a.dirt));
        }
    };
    const getGsrTurf = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.turf - b.turf));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.turf - a.turf));
        }
    };
    const getGsrSynth = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.synthetic - b.synthetic));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.synthetic - a.synthetic));
        }
    };
    const getGsrWet = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.wet - b.wet));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.wet - a.wet));
        }
    };

    return (
        <>
            <tr className="table__row table__row_content" {...props} onClick={() => handleShow(1)}
                style={{cursor: "pointer"}}>
                <td className="px-2 py-3">
                    {showGsrGrid ?
                        <i className="fas fa-caret-down" style={{marginTop: "2px"}}></i> :
                        <i className="fas fa-caret-right" style={{marginTop: "2px"}}></i>}
                </td>
                {!showFavoriteButton ? null : (<td className="px-2 py-3">
                    <FavoriteIndicator
                        isFavorite={item.isFavorite}
                        onClick={async (e) => {
                            e.stopPropagation();
                            handleFavoriteClick(item.id, !item.isFavorite);
                        }}
                    />
                </td>)}
                {!showSelectCheckBox ? null : (
                    <td className="px-2 py-3">
                        <ItemCheckBox
                            isChecked={isSelected}
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelectSingleClick(item.id)
                            }}
                        />
                    </td>
                )}
                {/*<td className="py-3 ps-3">{item[idFieldName]}</td>*/}
                <td className="py-3">
                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center" style={isCpuSelectedStyle(item.isSireCpuSelected)}>
                            {item.sireName}
                        </div>
                        {/*<CpuSelected isCpuSelected={item.isSireCpuSelected}/>*/}
                    </div>
                </td>
                <td className="py-3">
                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center" style={isCpuSelectedStyle(item.isDamCpuSelected)}>
                            {item.damName}
                        </div>
                        {/*<CpuSelected isCpuSelected={item.isDamCpuSelected}/>*/}
                    </div>
                </td>
                <td className="py-3"><span>{item.damSireName}</span></td>
                <td className="py-3"><span style={truncateCell()}>{item.horseName}</span></td>
                <td className="py-3 ps-3" style={{textTransform: "capitalize"}}>
                    {item?.sex?.length > 1 ? `${item?.sex?.substring(0, 1)}` : item?.sex}
                </td>
                <td className="py-3 ps-2">{item.hipNumber}</td>
                <td className="py-3 ps-3">{item.gsrDirt}</td>
                <td className="py-3 ps-3">{item.gsrTurf}</td>
                <td className="py-3 ps-4">{item.gsrSynth}</td>
                <td className="py-3 ps-3">{item.gsrWet}</td>
                <td className="py-3 ps-3">${formatNumber(item.price)}</td>
                <td className="py-3">
                    <div
                        className="table__comment"
                        style={{cursor: "pointer"}}
                        onClick={(e) => {
                            e.stopPropagation();
                            setHorseEdit(item);
                            handleNoteShow();
                        }}
                    >
                        <img
                            src="/assets/img/icon/chat.svg"
                            style={{
                                width: "21.68px",
                                height: "21.68px",
                            }}
                        />
                        <span className="comment__badge">{item.horseNotes.length}</span>
                    </div>
                </td>
                {allowHorseEdit ? <td className="py-3">
                    <div className="d-flex justify-content-start gap-2">

                        <button
                            className="btn btn-default border site-s-font text-primary d-flex justify-content-center"
                            style={{
                                width: "73px",
                                height: "32px",
                                padding: "5px, 12px, 5px, 12px",
                                gap: "6px",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setHorseEdit(item);
                                setShow(true);
                            }}
                        >
                            <AiOutlineEdit style={{fontSize: "18px"}}/>
                        </button>
                    </div>
                </td> : null}
            </tr>
            {showGsrGrid ? (
                <>
                    {item.geneticStrengthRatings == "" ? (
                        <tr className="bg-gray" style={{ height: "37px" }}>
                            <td className="text-center" colSpan={14}>
                                No Data Found
                            </td>
                        </tr>
                    ) : (
                        <>
                            <tr
                                className=""
                                style={{ height: "37px", backgroundColor: `#E5E5E5` }}
                            >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan={2}>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                        }}
                                    >
                                        Distance/Furlongs{" "}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrDirt(`Dirt-${item.id}`)}
                                    >
                                        Dirt{" "}
                                        {gsrSort == `Dirt-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Dirt-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrTurf(`Turf-${item.id}`)}
                                    >
                                        Turf{" "}
                                        {gsrSort == `Turf-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Turf-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrSynth(`Synth-${item.id}`)}
                                    >
                                        Synth{" "}
                                        {gsrSort == `Synth-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Synth-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrWet(`Wet-${item.id}`)}
                                    >
                                        Wet{" "}
                                        {gsrSort == `Wet-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Wet-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {item.geneticStrengthRatings.map((item, index) => (
                                <>
                                    {item.distanceDisplay == "ALL" ? null : (
                                        <tr
                                            className=""
                                            style={{
                                                height: "37px",
                                                backgroundColor: `${index % 2 == 0 ? `#EFEFEF` : `#E5E5E5`
                                                    }`,
                                            }}
                                        >
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}>{item.distanceDisplay}</td>
                                            <td>{item.dirt}</td>
                                            <td>{item.turf}</td>
                                            <td>{item.synthetic}</td>
                                            <td>{item.wet}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </>
            ) : null}
        </>
    );
};

export default HorseTableRowView;

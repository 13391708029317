import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';

import debounce from 'lodash/debounce';

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, cfg, options, setOptions, ...props }) => {
    const [fetching, setFetching] = useState(false);
    //const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            if (!value || value.length < 3) {
                setOptions([]);
                return;
            }
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            fetchOptions(value, cfg).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout, cfg, setOptions]);
    return (
        <Select
            showSearch
            filterOption={false}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <div style={{ marginTop: '25px', paddingBottom: '25px' }}>
                <Spin size="medium" style={{ paddingLeft: '5px' }} tip="Retrieving horses..." >
                    <div className="content" /></Spin></div> : props.notFoundRender}
            {...props}
            options={options}
        />
    );
}

export default DebounceSelect;
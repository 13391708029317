/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Pagination } from 'antd';
import Layout from "./Layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EventModal from "./modal/EventModal";
import PromtModal from "./modal/PromotModal";
import MetaModal from "./modal/MetaModal";
import AddData from "./modal/AddData";
import { useGetEventsQuery } from "../features/api/eventApi";
import { useCreateEventMutation } from "../features/api/eventApi";
import { ToastContainer, toast } from "react-toastify";
import ShareModal from "./modal/ShareModal";
import EventUpdateModal from "./modal/EventUpdateModal";
import { useUpdateEventMutation } from "../features/api/eventApi";
import { baseUrl, API_URL } from "../config/config";
import { HiOutlineHome } from "react-icons/hi";
import { logout } from "../features/slices/authslice";
import { MdClear } from "react-icons/md";
import TableLoading from "./TableLoading";

const Events = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [showupdate, setShowupdate] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddMeta, setShowAddMeta] = useState(false);
  const [shareID, setShareID] = useState("");
  const [isMeta, setIsMeta] = useState(0);
  const [isAddData, setIsAddData] = useState(0);
  const [addshow, setAddShow] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [totalPage, setTotalPage] = useState(5);
  const [editEvent, setEditEvent] = useState({ id: "", name: "" });
  const [eventList, setEventList] = useState([]);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { data, error, isLoading } = useGetEventsQuery(currentPage, pageSize);

  if (error?.status === 401) {
    dispatch(logout());
  }

  // console.log('error', error);
  // console.log('useGetEventsQuery', useGetEventsQuery(currentPage, pageSize));

  const [
    createEvent,
    {
      data: eventData,
      isLoading: isGetLoading,
      isSuccess: isGetSuccess,
      isError,
      error: createError,
    },
  ] = useCreateEventMutation();

  if (createError?.status === 401) {
    dispatch(logout());
  }

  const [updateEvent] = useUpdateEventMutation();

  const [eventName, setEventName] = useState("");

  const [searchErr, setSearchErr] = useState(false);
  const [search, setSearch] = useState({
    name: "",
  });

  console.log("isError", isError);

  useEffect(() => {
    if (isLoading == true) {
      setLoading(true);
    } else {
      setEventList(data?.successData?.result);
    }
    setLoading(false);
  }, [data?.successData?.result]);

  const updateSearch = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleView = (id) => {
    navigate(`/event/${id}`);
  };

  const handleShare = async (id) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const searchInfo = await fetch(
      `${API_URL}/Event/createeventlink/${id}`,
      requestOptions
    );
    const result = await searchInfo.json();
    // console.log('handleShare result', result);
    setShareID(result.successData.token);
    setShowShare(true);
  };

  const handleUpdate = (event) => {
    setShowupdate(true);
    setEditEvent({ id: event.id, name: event.name });
  };

  const onSubmitUpdate = () => {
    if (editEvent.name != "") {
      updateEvent(editEvent)
        .unwrap()
        .then(() => {
          toast.success("Event Has Been Updated Successfully");
          setEditEvent({ id: "", name: "" });
          setShowupdate(false);
        })
        .then((error) => {
          console.log(error);
          setEventName("");
        });
    } else {
      toast.error("Event Field must not be empty");
    }
  };

  const saveEvent = () => {
    if (eventName != "") {
      createEvent(eventName)
        .unwrap()
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Event Has Been Created Successfully",
          });
          setEventName("");
          setShow(false);
        })
        .then((error) => {
          console.log(error);
          setEventName("");
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to Created Event!",
      });
    }
  };

  useEffect(() => {
    setIsSearch(false);

    if (isMeta) {
      setShowAdd(false);
      setShowAddMeta(true);
    }

    if (isAddData) {
      setShowAddMeta(false);
      setAddShow(true);
    }
  }, [isMeta, isAddData]);

  const getEventSearch = async (e, page, size) => {
    console.log('here');
    e.preventDefault();
    setLoading(true);

    if (search.name == "") {
      // setSearchErr(true);
      // Swal.fire({
      //     icon: 'error',
      //     title: 'Please Provide Event Name.',
      // })

      if (isLoading == true) {
        setEventList([]);
      } else {
        setEventList(data?.successData?.result);
      }
      setLoading(false);
    } else {
      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const searchInfo = await fetch(
        `${API_URL}/Event/index?searchBy=name&searchTerm=${search.name}&page=${page}&pageSize=${size}`,
        requestOptions
      );
      const result = await searchInfo.json();
      console.log("searchInfo result", result);

      if (searchInfo.status === 401) {
        dispatch(logout());
      }

      setCurrentPage(currentPage);
      console.log(result);
      if (result.successData == "") {
        setTotalPages(0);
        setTotalItems(0);
        setEventList([]);
      } else {
        setIsSearch(true);
        setTotalPages(result.successData.totalPages);
        setCurrentPage(result.successData.currentPage);
        setTotalItems(result.successData.totalItems);
        setEventList(result.successData.result);
      }
    }

    setLoading(false);
  };

  const ClearAll = () => {
    setEventList(data?.successData?.result);
    setSearch({ ...search, name: "" });
  };

  const handleSearchPagination = (page) => {
    setCurrentPage(page);
  };

  const handlePagination = (event, page, size) => {
    console.log(event, page, size);
    setLoading(true);
    setCurrentPage(page);
    setPageSize(size)
    getEventSearch(event, page, size);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <>
      <Layout>
        <EventUpdateModal
          isGetLoading={isGetLoading}
          eventName={editEvent}
          onSubmitUpdate={onSubmitUpdate}
          setEventName={setEditEvent}
          show={showupdate}
          onClose={() => setShowupdate(false)}
        />
        <EventModal
          isGetLoading={isGetLoading}
          eventName={eventName}
          saveEvent={saveEvent}
          setEventName={setEventName}
          show={show}
          onClose={() => setShow(false)}
        />
        <PromtModal
          showAdd={showAdd}
          onClose={() => {
            setShowAdd(false);
            setIsMeta(0);
          }}
          setIsMeta={setIsMeta}
        />
        <MetaModal
          showAddMeta={showAddMeta}
          onCloseMeta={() => setShowAddMeta(false)}
          setIsAddData={setIsAddData}
        />
        <AddData
          addshow={addshow}
          onClose={() => {
            setAddShow(false);
            setIsMeta(0);
          }}
        />
        <ShareModal
          showShare={showShare}
          onClose={() => setShowShare(false)}
          id={shareID}
        />

        <div className="bg__area__content">
          <div className="container">
            <div className="py-3">
              <div className="page_breadcum">
                <ul>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    <a href="/breed-horses">
                      <HiOutlineHome
                        style={{
                          color: "rgba(140, 140, 140, 1)",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginTop: "-4px",
                        }}
                      />{" "}
                      Breeding /
                    </a>{" "}
                  </li>
                  <li
                    className="active"
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {" "}
                    <Link to="/events">Events </Link>{" "}
                  </li>
                  {/* <li style={{
                                        color: 'rgba(140, 140, 140, 1)',
                                        fontSize: '14px',
                                        fontWeight: '400'

                                    }} className='active'> <a href="">Add to Event</a> </li> */}
                </ul>
              </div>

              <div className="table__header shadow-sm ">
                <h3
                  className="py-2"
                  style={{
                    fontSize: "36px",
                  }}
                >
                  Event List
                </h3>

                <div className="d-flex justify-content-between table__header__row">
                  <form
                    className="d-flex align-items-center mb-2 table__left_searchbtn gap-1"
                    onSubmit={(e) => getEventSearch(e, currentPage, pageSize)}
                  >
                    <div className="table_search_input input-group form-group position-relative">
                      <input
                        type="text"
                        className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""
                          }`}
                        name="name"
                        value={search.name}
                        onChange={updateSearch}
                        placeholder="Search by event name"
                      />
                      <span
                        className="input-group-append position-absolute"
                        style={{
                          zIndex: "10",
                          marginTop: "-2px",
                          right: "-7px",
                        }}
                      >
                        {search.name.length > 0 ? (
                          <div className="input-group-text border-0 bg-transparent ml-n5">
                            <span
                              className=""
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={ClearAll}
                            >
                              <MdClear className="clear_logo" />
                            </span>
                          </div>
                        ) : null}
                      </span>
                    </div>
                    <button
                      className="btn btn-primary"
                      style={{ width: "80px", height: "40px" }}
                    >
                      Search
                    </button>
                  </form>
                  <button
                    className="btn btn-primary site-s-font"
                    onClick={() => setShow(true)}
                    style={{ width: "170px", height: "40px" }}
                  >
                    Create New Event
                  </button>
                </div>

                <div className="table-responsive table__fix_head mt-1">
                  <table className="table">
                    <thead>
                      <tr className="table__head__row">
                        <th className="table__tdp" style={{ width: "72%" }}>
                          Event Name
                        </th>
                        <th className="table__tdp px-3" style={{ width: "28%" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        loading ?
                          <tr className="table__row table__row_content table-loading">
                            <td className="text-center" colSpan={2}>
                              <TableLoading />
                            </td>
                          </tr> : <>
                            {isLoading ? (
                              <tr className="table__row table__row_content table-loading">
                                <td className="text-center" colSpan={2}>
                                  <TableLoading />
                                </td>
                              </tr>
                            ) : (
                              <>
                                {eventList == "" ? (
                                  <tr className="table__row table__row_content">
                                    <td className="text-center" colSpan={2}>
                                      <div
                                        className="w-100 d-flex justify-content-center"
                                        style={{ height: "55vh" }}
                                      >
                                        <img
                                          src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                          alt=""
                                          style={{ width: "150px" }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {eventList.map((event) => (
                                      <tr
                                        key={event?.id}
                                        className="table__row table__row_content"
                                      >
                                        <td className="py-3">{event?.name}</td>
                                        <td className="py-3 gap-2 d-flex border-bottom-0">
                                          <button
                                            onClick={() => handleView(event?.id)}
                                            className="btn-simple"
                                            style={{
                                              width: "75px",
                                              height: "40px",
                                              padding:
                                                "5px, 16px, 5px, 16px !important",
                                            }}
                                          >
                                            View
                                          </button>

                                          <button
                                            className="btn-simple"
                                            onClick={() => handleUpdate(event)}
                                            style={{
                                              width: "75px",
                                              height: "40px",
                                              padding:
                                                "5px, 16px, 5px, 16px !important",
                                            }}
                                          >
                                            Edit
                                          </button>

                                          <button
                                            className="btn-simple text-primary"
                                            onClick={() => handleShare(event?.id)}
                                            style={{
                                              width: "100px",
                                              height: "40px",
                                              padding:
                                                "5px, 16px, 5px, 16px !important",
                                            }}
                                          >
                                            <img
                                              src="assets/img/ShareAlt.png"
                                              alt=""
                                            />{" "}
                                            Share
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </>
                      }
                    </tbody>
                  </table>
                </div>
                {isLoading ? null : (
                  <>
                    {isSearch ? (
                      <div className="table__pagination mt-3">
                        <Pagination
                          total={totalItems || 0}
                          onChange={(e, page, pageSize) => handlePagination(e, page, pageSize)}
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                        />
                      </div>
                    ) : (
                      <div className="table__pagination mt-3">

                        <Pagination
                          total={data?.successData?.totalItems}
                          onChange={(e, page, pageSize) => handlePagination(e, page, pageSize)}
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                        />
                      </div>
                    )}
                  </>
                )}

              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Events;

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import Layout from './Layouts/Layout'
import Loading from './Loading'

import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Space, Empty, Card } from 'antd';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MultipleHorses from "./modal/MultipleHorses";
import { useGetHorseQuery } from '../features/api/horseApi';
import { API_URL } from '../config/config'
import { logout } from '../features/slices/authslice';
import { debounceTime, Subject } from 'rxjs';
import BreedHorseTypeAhead, { BreedHorseSearchResultRender } from './BreedHorseTypeAhead';
import { ConfigProvider } from 'antd';
import { set } from 'mobx';


const SalseAssistant = () => {

  const inputRef = useRef(null);
  const lenRef = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBreed, setLoadingBreed] = useState(false);
  const [isSireLoad, setIsSireLoad] = useState(false);
  const [multipleLoad, setMultipleLoad] = useState(false);
  const [multipleBreed, setMultipleBreed] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [sireShow, setSireShow] = useState(false)
  const [sireData, setSireData] = useState([])
  const [sireInput, setSireInput] = useState({})

  const [sireSearchConfig, setSireSearchConfig] = useState({ horseName: '', isSire: true });
  const [damSearchConfig, setDamSearchConfig] = useState({ horseName: '', isSire: false });

  const [isDamLoad, setIsDamLoad] = useState(false);
  const [damShow, setDamShow] = useState(false);
  const [damData, setDamData] = useState([])
  const [damInput, setDamInput] = useState({})

  const [multiple, setMultiple] = useState({
    file: "",
  });

  const [sireFilterChangedSubject] = useState(() => new Subject());
  const [damFilterChangedSubject] = useState(() => new Subject());

  const [sireOptions, setSireOptions] = useState([]);
  const [damOptions, setDamOptions] = useState([]);
  // const { data } = useGetHorseQuery();

  const handleClose = (info) => {
    setShow(info);
  }

  useEffect(() => {
    // if (data?.isSuccess === true) {
    // console.log('mew', data.successData);
    // }
    setTimeout(() => {
      setIsLoading(false)
    }, 800)
  }, []);

  const openFileModal = () => {
    inputRef.current.click();
  }

  useEffect(() => {
    let handler = (e) => {
      if (!lenRef.current.contains(e.target)) {
        setSireShow(false);
        setDamShow(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  const onSireFilterChange = (e) => {
    // Notify subject about the filter change
    setSireInput({ ...sireInput, name: e });
    //sireFilterChangedSubject.next(e);
  };

  const onSireClear = (e) => {
    console.log('onSireClear', e);
    setSireOptions([]);
  }

  const onDamClear = (e) => {
    setDamOptions([]);
  }

  useEffect(() => {
  }, [])

  const onDamFilterChange = (e) => {
    // Notify subject about the filter change
    setDamInput({ ...damInput, name: e });
    //damFilterChangedSubject.next(e.target.value);
  };

  const getSireList = async (e) => {

    if (e.length >= 3 && !isSireLoad) {
      setIsSireLoad(true)
      setSireInput({ ...sireInput, name: e });
      setSireShow(true);

      console.log('Sire Input Length', e.length);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e}&isSire=true`, requestOptions);
      const result = await getBreedHorses.json();

      if (getBreedHorses.status === 401) {
        dispatch(logout());
      }

      setSireData(result.successData);
      setIsSireLoad(false)
    }
    // console.log('result', result.successData);
  };

  const addSireInfo = (regID, name, date,) => {
    setSireShow(false)
    setSireInput({ ...sireInput, regID: regID, name: name, date: date });
  };

  const getDamList = async (e) => {

    if (e.length >= 3 && !isDamLoad) {

      setIsDamLoad(true)
      setDamInput({ ...damInput, name: e });
      setDamShow(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const getBreedHorses = await fetch(`${API_URL}/BreedHorses/SearchByHorseName?horseName=${e}&isSire=false`, requestOptions);
      const result = await getBreedHorses.json();

      if (getBreedHorses.status === 401) {
        dispatch(logout());
      }

      setDamData(result.successData);
      setIsDamLoad(false)
    }
  };

  const addDamInfo = (regID, name, date,) => {
    setDamShow(false)
    setDamInput({ ...damInput, regID: regID, name: name, date: date });
  };

  const getBreed = async () => {
    setLoadingBreed(true);
    if (sireInput.regID == "" || damInput.regID == "") {
      toast.error("Please select sire and dam name");
      setLoadingBreed(false);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      var raw = JSON.stringify({
        sireEquibaseRegistrationNumber: sireInput.regID,
        damEquibaseRegistrationNumber: damInput.regID,
        sireName: sireInput.name,
        damName: damInput.name,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      const BreedHorsesPost = await fetch(`${API_URL}/BreedHorses/v2/ProgenySearch`, requestOptions);
      const resultPost = await BreedHorsesPost.json();

      if (BreedHorsesPost.status === 401) {
        dispatch(logout());
      } else {
        navigate(`/bred-horses?searchBy=${resultPost.successData.searchBy}&searchTerm=${resultPost.successData.searchTerm}`);
      }
      setLoadingBreed(false);
    }
  }

  const uploadFile = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    // console.log('file', e.target.files[0]);
    // console.log('fileType', fileType);

    if (fileType == 'application/vnd.ms-excel' || fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setMultiple({ ...multiple, file: e.target.files[0] });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Format. Please Upload a CSV or XLSX  File!',
      })
    }
  }

  const handleHistory = () => {
    navigate('/breed-history');
  }

  const handleView = () => {
    navigate('/bred-horses', { state: multipleBreed });
  }

  const SubmitHandler = async (e) => {
    e.preventDefault();

    let file = multiple.file
    let fileType = file.type

    if (multiple.file == '') {
      Swal.fire({
        icon: 'error',
        title: 'Please Upload a CSV or XLSX  File!',
      })
    } else {

      setMultipleLoad(true);

      // console.log('SubmitHandler file', file);
      // console.log('fileType', fileType);

      const auth = JSON.parse(localStorage.getItem("auth"));
      var myHeaders = new Headers();;
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      var formdata = new FormData();
      formdata.append("formFile", file, multiple.file.name);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      const response = await fetch(`${API_URL}/File/upload`, requestOptions);
      const result = await response.json();

      // console.log('response', response.status);

      if (response.status === 401) {
        dispatch(logout());
      }

      // console.log('response', result);
      setMultipleBreed(result)
      setShow(true);
      setMultipleLoad(false);
    }
  }

  // console.log('multiple', multiple);

  return (
    <>
      <MultipleHorses
        show={show}
        onClose={() => setShow(false)}
        handleClose={handleClose}
        handleHistory={handleHistory}
        handleView={handleView}
      />
      {isLoading ? <Loading /> : (
        <Layout>
          <div className='bg__area d-flex flex-column'>
            <div className='search_box form__box__area_two mb-2'>
              <h3
                className='form__heading text-dark'
                style={{
                  color: 'rgba(66, 66, 66, 1) !important',
                  fontSize: '24px',
                  fontWeight: '600',
                  lineHeight: '24px',
                  marginBottom: '30px',
                }}
              >Breed A New Horse</h3>

              <div className='form__box mt-3'>
                <form action="">
                  <div className='row' ref={lenRef}>

                    <div className='col-md-6'>
                      <div className='form-group position-relative'>
                        <label className='mb-3'>
                          <span className='text-danger'>*</span> Sire
                        </label>
                        <ConfigProvider theme={{ token: { fontSize: 'large' } }}>
                          <BreedHorseTypeAhead
                            allowClear={true}
                            autoClearSearchValue={true}
                            className="table_search_input form-control rounded input__field flex-md-grow-3"
                            name="name"
                            value={sireInput.name}
                            onChange={onSireFilterChange}
                            onClear={onSireClear}
                            options={sireOptions}
                            setOptions={setSireOptions}
                            autoComplete="off"
                            placeholder="Search Sire "
                            optionLabelProp="horseName"
                            variant="borderless"
                            cfg={sireSearchConfig}
                            optionRender={(item) => (
                              <BreedHorseSearchResultRender
                                item={item}
                                onClick={() => addSireInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                              />
                            )}
                          />
                        </ConfigProvider>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group position-relative'>
                        <label className='mb-3'>
                          <span className='text-danger'>*</span> Dam
                        </label>
                        <ConfigProvider theme={{ token: { fontSize: 'large' } }}>
                          <BreedHorseTypeAhead
                            className="table_search_input form-control rounded input__field flex-md-grow-3"
                            name="name"
                            value={damInput.name}
                            onChange={onDamFilterChange}
                            allowClear={true}
                            autoClearSearchValue={true}
                            onClear={onDamClear}
                            options={damOptions}
                            setOptions={setDamOptions}
                            autoComplete="off"
                            placeholder="Search Dam "
                            optionLabelProp="horseName"
                            variant="borderless"
                            cfg={damSearchConfig}
                            optionRender={(item) => (
                              <BreedHorseSearchResultRender
                                item={item}
                                onClick={() => addDamInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                              />
                            )}

                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </div>

                  <div className='form-group mt-3'>
                    {
                      loadingBreed ?
                        <button style={{ color: `white` }} className='btn btn-default rounded upload__btn d-flex justify-content-center align-center' disabled>
                          Processing...
                        </button>
                        : <span className='btn btn-default rounded upload__btn' onClick={getBreed} style={{ color: `white` }}>Breed</span>
                    }
                  </div>
                </form>
              </div>
            </div>

            <div className='fw-bold mt-2 mb-2' style={{
              fontFamily: 'Inter',
              fontSize: '20px',
              fontWeight: '600'
            }}>
              OR
            </div>

            <div className='search_box form__box__area_two mt-2'>
              <div className='d-flex justify-content-between'>
                <h3
                  className='form__heading_left text-dark'
                  style={{
                    color: 'rgba(66, 66, 66, 1) !important',
                    fontSize: '24px',
                    fontWeight: '600',
                    lineHeight: '24px',
                    marginBottom: '30px',
                  }}
                >
                  Breed Multiple New Horses
                </h3>
                <span className='help__text'>
                  <img
                    src='./assets/img/icon/question_cirele.svg'
                    className='help__icon'
                  />
                  Help
                </span>
              </div>

              <div className='form__box mt-3'>
                <form onSubmit={SubmitHandler}>
                  <div className='row'>

                    <div className='col-md-12'>
                      <div className='form-group'>
                        <div className='image__upload__area' onClick={openFileModal}>
                          {
                            multiple.file == '' ? <>
                              <img src="assets/img/icon/upload.png" alt="" />
                              <span className='upload__text'>Upload Excel / CSV File Here</span>
                            </> : <>
                              {multiple.file.name}
                            </>
                          }
                        </div>
                        <input
                          type="file"
                          style={{ display: 'none' }}
                          ref={inputRef}
                          onChange={(e) => uploadFile(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <a href='/sample_file.xlsx' className='mt-2 sample__download'>
                    Download Sample File
                  </a>

                  <div className='form-group mt-3'>
                    {
                      multipleLoad ?
                        <button style={{ color: `white` }} className='btn btn-default rounded upload__btn d-flex justify-content-center align-center' disabled>
                          Processing...
                        </button>
                        : <button type='submit' className='btn btn-default rounded upload__btn'>Upload & Breed</button>
                    }
                  </div>
                </form>
              </div>
            </div>
          </div >
        </Layout>
      )}


    </>
  )
}

export default SalseAssistant
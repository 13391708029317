/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Select } from 'antd';
import Swal from "sweetalert2";

import Layout from "./Layouts/Layout";
import NotesShareModal from "./modal/NotesShareModal";
import { API_URL } from "../config/config";

import { logout } from "../features/slices/authslice";
import { AiFillFilter } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { MdClear } from "react-icons/md";
import { JsonRequestBuilder } from '../common/http-builder';
import debounce from 'lodash/debounce';
import { useRef } from 'react';
import EventView from "./EventView";
import HorseTableView from "./HorseTableView";

const useSingleton = (cbArgs = {}, callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack(cbArgs);
    setHasBeenCalled(true);
}

class PaginationState {
    currentPage = 1;
    pageSize = 10;
}

class SortState {
    sortBy = "";
    sortDesc = false;
}

class HipNumberRequest {
    construct = (tokenId) => {
        const builder = new JsonRequestBuilder();
        const url = `${API_URL}/event/${tokenId}/token-hip-numbers`;
        return builder
            .init()
            .useMethod("GET")
            .withUrl(url)
            .useLoading(true)
            .get();
    };
}

const EventShare = () => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [hipNumber, setHipNumber] = useState("");

    const navigate = useNavigate();

    const dispatch = useDispatch();


    const debounceTimeout = 800;

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showShare, setShowShare] = useState(false);
    const [viewRow, setViewRow] = useState(0);
    const [tabActive, setTabActive] = useState(1);
    const [idFieldName, setIdFieldName] = useState("eventHorseId");

    const [showFavoriteButton,] = useState(true);
    const [showSelectCheckBox,] = useState(false);
    const [allowHorseEdit,] = useState(false);

    const [paginationFavorite, setPaginationFavorite] = useState(new PaginationState());
    const [sortFavorite, setSortFavorite] = useState(new SortState());
    const [recordCountFavorites, setRecordCountsFavorites] = useState({
        totalItems: 1,
        totalPages: 1
    });

    const [paginationAll, setPaginationAll] = useState(new PaginationState());
    const [sortAll, setSortAll] = useState(new SortState());
    const [recordCountsAll, setRecordCountsAll] = useState({
        totalItems: 1,
        totalPages: 1
    });

    const [horses, setHorses] = useState([]);
    const [horsesEdit, setHorsesEdit] = useState([]);

    const [favoritesHorses, setFavoritesHorses] = useState([]);
    const [favoritesHorsesEdit, setFavoritesHorsesEdit] = useState([]);

    const [eventDetails, setEventDetails] = useState([]);
    const [favoriteDetails, setFavoriteDetails] = useState([]);

    const [breedStatus, setBreedStatus] = useState("get");
    const [favoritesBreedStatus, setFavoritesBreedStatus] = useState("get");

    const [shareID, setShareID] = useState("");

    const [searchErr, setSearchErr] = useState(false);

    const [searchHorseName, setSearchHorseName] = useState("");
    const [searchHorseCommand, setSearchHorseCommand] = useState(null);

    /* Notes*/
    const [noteShow, setNoteShow] = useState(false);
    /* Notes*/

    const [hipNumbers, setHipNumbers] = useState([]);
    const [hipNumberOptions, setHipNumberOptions] = useState([]);
    const [hipNumbersLoading, setHipNumbersLoading] = useState(false);

    const updateSearch = (e) => {
        setSearchHorseName(e.target.value);
    };

    const updateFilter = (newVal) => {
        if (newVal == "") {
            ClearAllHip();
        } else {
            setSearchParams(searchParams?.set('hipNumber', newVal));
        }
        handleHipNumberFilter(newVal);
    };

    const fetchData = async (url, successCb, failureCb) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        const result = await fetch(url, requestOptions);
        const data = await result.json();
        //console.log("result", data);

        if (result.isSuccess == false) {
            failureCb && failureCb();
        } else {
            successCb && successCb(data.successData);
        }

        return { isSuccess: data.isSuccess, data: data.successData };
    }

    const favoritesPath = `${API_URL}/Event/bytoken/ro/${id}/favorites`;
    const allPath = `${API_URL}/Event/bytoken/ro/${id}`;

    const buildUrl = (basePath, pagination, sort) => {
        const page = pagination.currentPage;
        const size = pagination.pageSize;
        const sortBy = sort.sortBy;
        const sortDesc = sort.sortDesc;
        const hips = buildHipNumbersQueryString();
        const horseSearch = horseNameSearchQueryString();

        return `${basePath}?${horseSearch}orderBy=${sortBy}&orderByDesc=${sortDesc}&${hips}page=${page}&size=${size}`;
    }
    const allFetchRef = useRef(0);
    const getAllEventDetails = () => {
        setLoading(true);

        allFetchRef.current += 1;
        const fetchId = allFetchRef.current;

        const url = buildUrl(allPath, paginationAll, sortAll);
        (fetchData(url)).then((result) => {
            if (fetchId !== allFetchRef.current) {
                // for fetch callback order
                return;
            }

            if (!result.isSuccess) {
                setEventDetails([]);
                return;
            }

            setEventDetails(result.data);
            const newVal = { totalItems: result.data.horses.totalItems, totalPages: result.data.horses.totalPages };
            setRecordCountsAll(newVal);
        }).finally(() => setTimeout(() => {
            setLoading(false);
        }, 1200));
    }

    const loadHipNumbers = async () => {
        setHipNumbersLoading(true);
        //console.log('fetching events', id);
        const hnr = new HipNumberRequest();
        const req = hnr.construct(id);

        await fetch(req.url, req.requestInit)
            .then((response) => response.json())
            .then((body) => {
                console.debug(body);
                return body.successData.map((h) => ({
                    label: h,
                    value: h,
                }));
            })
            .then((data) => setHipNumberOptions(data))
            .finally(() => setHipNumbersLoading(false));

        ;
    };

    const favoritesFetchRef = useRef(0);
    const getFavoritesEventDetails = async () => {
        setLoading(true);

        favoritesFetchRef.current += 1;
        const fetchId = favoritesFetchRef.current;

        const url = buildUrl(favoritesPath, paginationFavorite, sortFavorite);
        (fetchData(url)).then((result) => {
            if (fetchId !== favoritesFetchRef.current) {
                // for fetch callback order
                return;
            }

            if (!result.isSuccess) {
                setFavoriteDetails([]);
                return;
            }

            setFavoriteDetails(result.data);
            const newVal = { totalItems: result.data.horses.totalItems, totalPages: result.data.horses.totalPages };
            setRecordCountsFavorites(newVal);
        }).finally(() => setTimeout(() => {
            setLoading(false);
        }, 1200));
    }

    const horseNameSearchQueryString = () => (!searchHorseName) ? "" : `searchBy=sireOrDamName&searchTerm=${searchHorseName}&`;

    const handleHipNumberFilter = async (e) => {
        e.preventDefault && e.preventDefault();
        const newVal = typeof (e) == "string" ? e : e.target[0]["value"];
        setHipNumber(newVal);
        resetPaginations();
    }

    const buildHipNumbersQueryString = () => {
        if (!hipNumbers || hipNumbers?.length == 0) return "";

        const retval = `hipnumbers=${hipNumbers.map((h) => h.value).join('&hipNumbers=')}&`;
        return retval;
    }

    const clearAll = () => {
        setSortAll({ sortBy: "EventHorseId", sortDesc: true });
        setSortFavorite({ sortBy: "EventHorseId", sortDesc: true });
        resetPaginations();
        setSearchHorseName("");
    };

    const ClearAllHip = () => {
        setSearchParams(searchParams?.delete('hipNumber'));
        setHipNumber("");
        setHipNumbers([]);
    };

    const resetPaginations = () => {
        setPaginationAll({ ...paginationAll, currentPage: 1 });
        setPaginationFavorite({ ...paginationFavorite, currentPage: 1 });
    }

    const updateAllTabPageAndSize = (pageInfo) => {
        const newAllVal = { ...paginationAll, ...pageInfo };
        setPaginationAll(newAllVal);
    }

    const updateFavoritesTabPageAndSize = (pageInfo) => {
        const newFavVal = { ...paginationFavorite, ...pageInfo };
        setPaginationFavorite(newFavVal);
    }

    const handleAllTabPagination = (page, size) => {
        size = size ?? paginationAll.pageSize;
        updateAllTabPageAndSize({ currentPage: page, pageSize: size });
    }

    const handleFavoritesTabPagination = (page, size) => {
        size = size ?? paginationFavorite.pageSize;
        updateFavoritesTabPageAndSize({ currentPage: page, pageSize: size });
    }


    useEffect(() => {
        debounce(() => {
            getAllEventDetails();
        }, 300)();
    }, [hipNumbers, sortAll, paginationAll]);

    useEffect(() => {
        debounce(async () => {
            getAllEventDetails();
        }, debounceTimeout)();
    }, [searchHorseCommand]);

    useEffect(() => {
        debounce(async () => {
            await getFavoritesEventDetails();
        }, 300)();
    }, [hipNumbers, sortFavorite, paginationFavorite]);

    useEffect(() => {
        debounce(async () => {
            await getFavoritesEventDetails();
        }, debounceTimeout)();
    }, [searchHorseCommand]);

    const onAllSortClick = (sortName) => {
        if (sortName == sortAll.sortBy) {
            setSortAll({ ...sortAll, sortDesc: !sortAll.sortDesc });
        } else {
            setSortAll({ sortBy: sortName, sortDesc: false });
        }
    }

    const onFavoritesSortClick = (sortName) => {
        if (sortName == sortFavorite.sortBy) {
            setSortFavorite({ ...sortFavorite, sortDesc: !sortFavorite.sortDesc });
        } else {
            setSortFavorite({ sortBy: sortName, sortDesc: false });
        }
    }

    const handleTabClick = (newTab) => {
        setTabActive(newTab);
    };

    const handleAllEventFavoriteClick = async (horseId, isFavorite) => {
        const url = `${API_URL}/event/${eventDetails.id}/toggle-favorite`;
        const body = { horseId: horseId, isFavorite };
        const scb = (data) => {
            // update the pages with the new data
            // NOTE: need to do a data callback because of the pagination
            // TODO: fix this so it doesn't need to call back and this can be done locally
            getAllEventDetails();
            getFavoritesEventDetails();
        };

        const fcb = () => {
            Swal.fire({
                icon: 'error',
                title: 'Favorite Updating Failed!',
            });
        };
        await putData(url, body, scb, fcb);
    }

    const putData = async (url, body, successCb, failureCb) => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(body),
            redirect: "follow",
        };

        try {
            const result = await fetch(url, requestOptions);
            const data = await result.json();
            //console.log("result", data);
            if (result.status === 401) {
                console.log(result);
                return logout();
            }

            if (data.isSuccess == true) {
                successCb && successCb(data.successData);
            } else {
                failureCb && failureCb();
            }
        } catch (error) {
            console.log(error);
            failureCb();
        }
        setTimeout(() => {
            setLoading(false);
        }, 1200);

    }

    // put this last to do any initializations
    useSingleton({ eventId: id }, async (p) => {
        await loadHipNumbers(p.eventId);
    });

    return (
        <>
            <Layout>
                <NotesShareModal
                    noteShow={noteShow}
                    setNoteShow={setNoteShow}
                    onClose={() => setNoteShow(false)}
                    horsesEdit={horsesEdit}
                    setHorsesEdit={setHorsesEdit}
                    pageSize={paginationAll.pageSize}
                    currentPage={paginationAll.currentPage}
                    //setCurrentPage={setCurrentPage}
                    handlePagination={handleAllTabPagination}
                />
                <div className="bg__area__content">
                    <div className="container">
                        <div className="py-2">
                            {/* <div >
                                <ul>
                                    <li>
                                        {" "}
                                        <Link to="#">
                                            <i className="fas fa-house"></i> Events /
                                        </Link>{" "}
                                    </li>
                                    <li className="active">
                                        {" "}
                                        <Link to={`/share/${id}`}>Event Details </Link>{" "}
                                    </li>
                                </ul>
                            </div> */}

                            <div className="table__header shadow-sm" style={{ marginTop: "5rem" }}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>{eventDetails?.name}</h3>
                                </div>

                                <div className="table__Tabs">
                                    <div
                                        className={`single-tab ${tabActive == 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        All Horses
                                    </div>
                                    <div
                                        className={`single-tab ${tabActive == 2 ? "active" : ""}`}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Favorite Horses
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between table__header__row">
                                    <div className="d-flex align-items-center gap-2 second-search__box">
                                        <form
                                            className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                setSearchHorseCommand(new Date());
                                                resetPaginations();
                                            }}
                                        >
                                            <div className="table_search_input input-group form-group position-relative">
                                                <input
                                                    type="text"
                                                    className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                                                    name="name"
                                                    value={searchHorseName}
                                                    onChange={updateSearch}
                                                    placeholder="Search by sire or dam name"
                                                    autocomplete="off"
                                                />
                                                <span
                                                    className="input-group-append position-absolute"
                                                    style={{
                                                        zIndex: "10",
                                                        marginTop: "-2px",
                                                        right: "-7px",
                                                    }}
                                                >
                                                    {searchHorseName.length > 0 ? (
                                                        <div className="input-group-text border-0 bg-transparent ml-n5">
                                                            <span
                                                                className=""
                                                                style={{
                                                                    cursor: "pointer",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                                onClick={clearAll}
                                                            >
                                                                <MdClear className="clear_logo" />
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <button
                                                className="btn btn-primary"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "22px",
                                                    marginRight: "5px",
                                                }}
                                            >
                                                Search
                                            </button>
                                        </form>

                                        <form
                                            className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                                            onSubmit={handleHipNumberFilter}
                                        >
                                            <div className="table_search_input input-group form-group position-relative">
                                                <Select
                                                    className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                                                    placeHolder="Filter by hip numbers"
                                                    showSearch
                                                    labelInValue
                                                    filterOption={true}
                                                    mode="multiple"
                                                    value={hipNumbers}
                                                    loading={hipNumbersLoading}
                                                    onChange={(newValue) => {
                                                        setHipNumbers(newValue);
                                                    }}
                                                    variant="borderless"
                                                    style={{
                                                        width: '100%',

                                                    }}
                                                    options={(hipNumberOptions || [])}
                                                />
                                            </div>
                                            <button className="btn btn-primary">
                                                {/* <i className="fas fa-filter" style={{ fontSize: 12 }}></i>  */}
                                                <AiFillFilter
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    Filter
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                </div>

                                {tabActive == 1 ? (
                                    <div>
                                        <HorseTableView
                                            data={eventDetails?.horses?.result}
                                            baseUrl={allPath}
                                            loading={loading}
                                            setLoading={setLoading}
                                            horses={horses}
                                            setHorses={setHorses}
                                            setShow={setShow}
                                            noteShow={noteShow}
                                            setNoteShow={setNoteShow}
                                            horseEdit={horsesEdit}
                                            setHorseEdit={setHorsesEdit}
                                            className="table mt-1"
                                            getData={getAllEventDetails}
                                            handleFavoriteClick={handleAllEventFavoriteClick}
                                            setPaginationState={setPaginationAll}
                                            paginationState={paginationAll}
                                            setSort={setSortAll}
                                            sort={sortAll}
                                            onSortClick={onAllSortClick}
                                            recordCount={recordCountsAll}
                                            allowHorseEdit={allowHorseEdit}
                                            showFavoriteButton={showFavoriteButton}
                                            showSelectCheckBox={showSelectCheckBox}
                                            idFieldName={idFieldName}
                                        />
                                    </div>
                                ) : (
                                    //need to hack this because of a bug in the pager. Do not remove the span tag.
                                    <div><span style={{ maxHeight: "1px", margin: "0" }}></span>
                                        <HorseTableView
                                            data={favoriteDetails?.horses?.result}
                                            baseUrl={favoritesPath}
                                            loading={loading}
                                            setLoading={setLoading}
                                            horses={favoritesHorses}
                                            setHorses={setFavoritesHorses}
                                            setShow={setShow}
                                            noteShow={noteShow}
                                            setNoteShow={setNoteShow}
                                            horseEdit={horsesEdit}
                                            setHorseEdit={setHorsesEdit}
                                            className="table mt-1"
                                            getData={getFavoritesEventDetails}
                                            handleFavoriteClick={handleAllEventFavoriteClick}
                                            setPaginationState={setPaginationFavorite}
                                            paginationState={paginationFavorite}
                                            setSort={setSortFavorite}
                                            sort={sortFavorite}
                                            onSortClick={onFavoritesSortClick}
                                            recordCount={recordCountFavorites}
                                            allowHorseEdit={allowHorseEdit}
                                            showFavoriteButton={showFavoriteButton}
                                            showSelectCheckBox={showSelectCheckBox}
                                            idFieldName={idFieldName}
                                        /></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    );
};

export default EventShare
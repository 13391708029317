import React from 'react';
import SortableColumn from './elements/SortableColumn';
import ItemCheckBox from './ItemCheckBox';

const HorseTableColumns = ({ sort, setSort, onSortClick, showSelectCheckBox, checkedAll, setCheckedAll, allowHorseEdit, ...props }) => {

    return (<tr className="table__head__row">
        <th
            className="table__tdp"
            style={{ width: "32px" }}
        >
        </th>
        <th
            className="table__tdp"
            style={{ width: "48px" }}
        >
            {!showSelectCheckBox ? null :
                (<ItemCheckBox
                    isChecked={checkedAll}
                    onClick={() => setCheckedAll(!checkedAll)}
                />
                )
            }</th>
{/*        <SortableColumn
            className="table__tdp"
            style={{ width: "60px !important", paddingLeft: "0px" }}
            columnTitle={"ID"}
            sortName={"eventHorseId"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />*/}
        <SortableColumn
            className="table__tdp"
            style={{ width: "130px !important", paddingLeft: "0px" }}
            columnTitle={"Sire Name"}
            sortName={"sireName"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "130px !important", paddingLeft: "0px" }}
            columnTitle={"Dam Name"}
            sortName={"damName"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "115px !important", paddingLeft: "0px" }}
            columnTitle={"Sire Of Dam"}
            sortName={"sireOfDam"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "115px !important", paddingLeft: "0px" }}
            columnTitle={"New Horse"}
            sortName={"horseName"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "60px !important", paddingLeft: "0px" }}
            columnTitle={"Sex"}
            sortName={"sex"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "90px !important", paddingLeft: "0px" }}
            columnTitle={"Hip"}
            sortName={"hipNumber"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "90px !important", paddingLeft: "0px" }}
            columnTitle={"Dirt"}
            sortName={"gsrDirt"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "90px !important", paddingLeft: "0px" }}
            columnTitle={"Turf"}
            sortName={"gsrTurf"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "90px !important", paddingLeft: "0px" }}
            columnTitle={"Synth"}
            sortName={"gsrSynth"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "90px !important", paddingLeft: "0px" }}
            columnTitle={"Wet"}
            sortName={"gsrWet"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <SortableColumn
            className="table__tdp"
            style={{ width: "100px !important", paddingLeft: "0px" }}
            columnTitle={"Price"}
            sortName={"price"}
            sortState={sort}
            setSortState={setSort}
            onSortClickHandler={onSortClick} />
        <th
            className="table__tdp"
            style={{ width: "70px", paddingLeft: "0px" }}
        >
            Notes
        </th>
        {allowHorseEdit ?
            <th
                className="table__tdp"
                style={{ width: "50px", paddingLeft: "0px" }}
            >
                Action
            </th> : null}
    </tr>);
}

export default HorseTableColumns;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import { baseUrl, API_URL } from "../../config/config";
import ListGroup from "react-bootstrap/ListGroup";
import { logout } from "../../features/slices/authslice";

const NotesModal = ({
  noteShow,
  setNoteShow,
  horsesEdit,
  setHorsesEdit,
  pageSize,
  currentPage,
  setCurrentPage,
  handlePagination,
}) => {
  if (!noteShow) return;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [note, setNote] = useState({
    note: "",
    horseId: horsesEdit?.id == "" ? "" : horsesEdit?.id,
  });

  const [noteErroer, setNoteError] = useState(false);
  const handleNoteClose = () => setNoteShow(false);
  const handleNoteShow = () => setNoteShow(true);
  const updateNote = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const NoteDelHandler = async (id) => {
    Swal.fire({
      title: "Are You Sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete It!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
          method: "DELETE",
          body: JSON.stringify({
            ...note,
          }),
          headers: myHeaders,
          redirect: "follow",
        };

        const delNote = await fetch(
          `${API_URL}/Note/delete/${id}`,
          requestOptions
        );
        const DelResult = await delNote.json();

        if (delNote.status === 401) {
          dispatch(logout());
        }

        //console.log("DelResult", DelResult);

        if (DelResult.isSuccess == true) {
          setNote({ ...note, note: "" });
          handlePagination(currentPage, pageSize);
          Swal.fire({
            icon: "success",
            title: "Note Has Been Deleted Successfully!",
          });

          const getRequestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          const horsesGet = await fetch(
            `${API_URL}/Horse/byid/${horsesEdit.id}`,
            getRequestOptions
          );
          const horsesResult = await horsesGet.json();

          if (horsesGet.status === 401) {
            dispatch(logout());
          }

          console.log("horsesResult", horsesResult.successData);

          setHorsesEdit(horsesResult.successData);
        } else {
          Swal.fire({
            icon: "error",
            title: "Note Has Not Been Deleting Successfully!",
          });
        }
      }
    });

    setLoading(false);
    setLoadingSave(false);
  };

  async function SubmitHandler(e) {
    e.preventDefault();

    if (note.note == "") {
      setNoteError(true);
    } else {
      setNoteError(false);
      setLoading(true);
      
      const auth = JSON.parse(localStorage.getItem("auth"));
      const userId = auth.userId;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          ...note,
          createdBy: userId,
        }),
        headers: myHeaders,
        redirect: "follow",
      };

      const create = await fetch(`${API_URL}/Note/create`, requestOptions);
      const result = await create.json();

      if (create.status === 401) {
        dispatch(logout());
      }

      // console.log('result', result);

      if (result.isSuccess == true) {
        setNote({ ...note, note: "" });
        handlePagination(currentPage, pageSize);
        setLoading(false);

        Swal.fire({
          icon: "success",
          title: "Note Has Been Added Successfully!",
        });

        const getRequestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const horsesGet = await fetch(
          `${API_URL}/Horse/byid/${horsesEdit.id}`,
          getRequestOptions
        );
        const horsesResult = await horsesGet.json();

        if (horsesGet.status === 401) {
          dispatch(logout());
        }

        console.log("horsesResult", horsesResult.successData);

        setHorsesEdit(horsesResult.successData);
      } else {
        Swal.fire({
          icon: "error",
          title: "Note Has Not Been Added Successfully!",
        });
      }
      setLoading(false);
    }
  }

  return (
    <div className="modal__wrapper">
      <div className="modal__card px-5 py-4">
        <div className="modal__head">
          <h3>Total Notes ({horsesEdit.horseNotes.length})</h3>
          <span onClick={handleNoteClose}>
            <i className="fas fa-times"></i>
          </span>
        </div>

        <div className="row mt-3 modal__form">
          <div
            className="col-md-12 scrollbar overflow-auto"
            style={{ maxHeight: "300px" }}
          >
            <div className="row w-100">
              {horsesEdit.horseNotes == "" ? null : (
                <>
                  {horsesEdit.horseNotes.map((item, index) => (
                    <div
                      key={index}
                      className="col-md-12  p-2 mt-2 mb-2 text-start"
                    >
                      <h4 className="label__1 w-100 d-flex justify-content-between">
                        <span>
                          {moment(item.createdAt).format("DD MMMM YYYY")}
                        </span>
                        <i
                          className="fas fa-trash text-danger"
                          onClick={() => NoteDelHandler(item.id)}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </h4>
                      <p className="pera__1 w-100 ">{item.note}</p>
                      <p className="w-100 mt-1 border-bottom"></p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>

          <div className="col-md-12 mt-3 mb-3">
            <textarea
              className="form-control textarea w-100"
              placeholder="Write here"
              rows={3}
              name="note"
              value={note.note}
              onChange={updateNote}
              required
              disabled={horsesEdit?.id == "" ? true : false}
            ></textarea>

            {noteErroer ? (
              <div className="text-danger mt-2">This field is required</div>
            ) : null}
          </div>
          <div className="col-md-12 mb-2 text-start">
            {loading ? (
              <button className="btn btn-primary">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            ) : (
              <button
                className="btn btn-primary site-s-font"
                onClick={SubmitHandler}
                disabled={horsesEdit?.id == "" ? true : false}
              >
                <i className="fas fa-plus-circle"></i> Add Note
              </button>
            )}
          </div>

          <div className="modal__footer">
            <div className="col-md-12 d-flex justify-content-end gap-2">
              <button
                className="btn btn-defaut border site-s-font"
                onClick={handleNoteClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesModal;

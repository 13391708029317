/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import cookie from "js-cookie";
import Swal from "sweetalert2";
import moment from "moment";

// import { useLocation } from 'react-router';

import { ToastContainer, toast } from "react-toastify";
import Layout from "./Layouts/Layout";
import Modal from "./modal/Modal";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { baseUrl, API_URL } from "../config/config";

import { HiOutlineHome } from "react-icons/hi";

import ViewBredRow from "./ViewBredRow";
import { logout } from "../features/slices/authslice";
import { AiFillFilter } from "react-icons/ai";
import { FaSort } from "react-icons/fa6";
import { useDispatch } from "react-redux";

const BreedHistory = (...props) => {
  const [loading, setLoading] = useState(true);
  const [historyList, setHistoryList] = useState([]);

  const getBreedHistory = async () => {
    setLoading(true);

    const auth = JSON.parse(localStorage.getItem("auth"));

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${auth.token}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const fileHistory = await fetch(
        `${API_URL}/UploadFile/index`,
        requestOptions
      );
      const result = await fileHistory.json();
      console.log("fileHistory :::", result);

      if (fileHistory.status === 401) {
        dispatch(logout());
      }

      if (result.isSuccess == false) {
        setHistoryList([]);
      } else {
        setHistoryList(result?.successData?.result);
      }
    } catch (err) {
      setHistoryList([]);
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getBreedHistory();
    setLoading(false);
  }, []);

  return (
    <>
      <Layout>
        <div className="bg__area__content">
          <div className="container">
            <div className="py-3">
              <div className="page_breadcum">
                <ul>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <a href="">
                      <HiOutlineHome
                        style={{
                          color: "rgba(140, 140, 140, 1)",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginTop: "-4px",
                        }}
                      />{" "}
                      Breeding /
                    </a>
                  </li>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    className="active text-dark"
                  >
                    <a href="">History</a>
                  </li>
                </ul>
              </div>

              <div className="table__header shadow-sm">
                <h3 className="py-2">History</h3>

                <div className="table-responsive table__fix_head mt-1">
                  <table className="table">
                    <tr className="table__head__row">
                      <th className="table__tdp" style={{ width: "10%" }}>
                        Date
                      </th>
                      <th className="table__tdp" style={{ width: "80%" }}>
                        File Name
                      </th>
                      <th className="table__tdp" style={{ width: "10%" }}>
                        Action
                      </th>
                    </tr>
                    <tbody>
                      {loading ? (
                        <tr className="table__row table__row_content">
                          <td className="text-center" colSpan={3}>
                            Loading...
                          </td>
                        </tr>
                      ) : (
                        <>
                          {historyList == "" ? (
                            <tr className="table__row table__row_content">
                              <td className="text-center" colSpan={3}>
                                Loading...
                              </td>
                            </tr>
                          ) : (
                            <>
                              {historyList.map((item, index) => (
                                <tr
                                  key={index}
                                  className="table__row table__row_content"
                                  style={{ height: "42px" }}
                                >
                                  <td className="text-start px-2 py-3">
                                    {moment(item.succeededAt).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td className="text-start px-2 py-3">
                                    {item.fileName}
                                  </td>
                                  <td className="text-start px-2 py-3 d-flex align-center justify-content-start">
                                    <button
                                      className="btn btn-default border site-s-font"
                                      style={{ width: "100px" }}
                                    >
                                      View Results
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BreedHistory;

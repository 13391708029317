/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Layout from './Layouts/Layout'
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from '../features/api/authApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken } from '../features/slices/authslice';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { logout } from '../features/slices/authslice';



const Login = () => {

    const token = useSelector(state => state.auth.token)
    const [passShow, setPassShop] = useState(false);
    const [processToken, setProcessToken] = useState('');
    const [makeLogin, { data, isLoading, isError, isSuccess, error }] = useLoginMutation()

    // console.log('token33', data);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const nextRoute = (e) => {
        e.preventDefault()
        navigate('/breed-horses')
    }

    const [user, setUser] = useState({
        email: '',
        password: '',
        deviceId: "",
        code: "1111"
    })

    const ShowHandel = () => {
        if (passShow == false) {
            setPassShop(true)
        } else {
            setPassShop(false)
        }
    }

    const processLogin = async (e) => {
        e.preventDefault()
        const tst = await makeLogin(user)

        if (data?.isSuccess === true) {
            setProcessToken(data?.successData?.token)
            toast.success("Successfully Logged in.")
            // dispatch(setToken({ user: user.email, userId: user.userId, token: data?.successData?.token }))
            navigate('/breed-horses')
        } else {
            toast.error("Invalid Login")
        }
    }

    useEffect(() => {
        if (data?.isSuccess === true) {
            dispatch(setToken({
                user: user.email,
                userId: user.userId,
                token: data?.successData?.token,
                refreshToken: data?.successData?.refreshToken,
                expiry: data?.successData?.expiry
            }))
            navigate('/breed-horses')
        }
    }, [data?.isSuccess])

    useEffect(() => {
        if (token != null) {
            navigate('/breed-horses')
        }
    }, [token])

    return (
        <Layout>

            <div className='bg__area'>
                <div className='form__box__area login_box'>
                    <h3
                        className='form__heading_center'
                        style={{
                            fontSize: '28px',
                            fontWeight: '600',
                            lineHeight: '24px',
                            color: 'rgba(66, 66, 66, 1) !important',
                        }}
                    >Log In</h3>

                    <div className='form__box mt-5'>
                        <form action="">
                            <div className='form-group mb-3'>
                                <input type="email" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} className='form-control rounded input__field' placeholder='Email' />
                            </div>

                            <div className="input-group form-group  mb-2 position-relative">
                                <input
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    value={user.password}
                                    type={passShow ? `text` : `password`}
                                    className="form-control rounded input__field border-end-0'" placeholder='Password' />
                                <span
                                    className="input-group-append position-absolute"
                                    style={{
                                        zIndex: '10',
                                        marginTop: '6px',
                                        right: '3px'
                                    }}>
                                    <div className="input-group-text border-0 bg-transparent ml-n5">
                                        {
                                            passShow ?
                                                <AiOutlineEye onClick={ShowHandel} style={{ cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0)', fontSize: "16px", color: 'rgba(140, 140, 140, 1)' }} /> :
                                                <AiOutlineEyeInvisible onClick={ShowHandel} style={{ cursor: 'pointer', backgroundColor: 'rgba(255, 255, 255, 0)', fontSize: "16px", color: 'rgba(140, 140, 140, 1)' }} />
                                        }
                                    </div>
                                </span>
                            </div>

                            <div className='form-group forgot__password'>
                                <a href="/forgot-password" className='forgot__password'>Forgot Password?</a>
                            </div>

                            <div className='form-group mt-3'>
                                <button className='btn btn-default rounded login__btn' onClick={processLogin} disabled={isLoading} style={{ color: `${isLoading ? `white` : ``}` }}>
                                    Log in {isLoading ? 'Processing...' : ''}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Login
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';

import { Pagination } from 'antd';
import Layout from './Layouts/Layout'
import Modal from './modal/Modal'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { baseUrl, API_URL } from '../config/config'
import { HiOutlineHome } from "react-icons/hi";


import ViewBredRow from './ViewBredRow'
import { logout } from '../features/slices/authslice';
import { PiCaretUpDownFill } from "react-icons/pi";

import { useDispatch } from 'react-redux';


const EventBreed = (...props) => {

    const dispatch = useDispatch()

    const location = useLocation();
    const query = queryString.parse(location.search);
    // console.log('queryString', query);

    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [breedHorsesList, setBreedHorsesList] = useState([]);

    const getBreedHorses = async (page, size) => {
        setLoading(true);
        let result;
        let requestOptions;

        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        if (!query) {
            var raw = JSON.stringify({
                "sireEquibaseRegistrationNumber": query.sid,
                "damEquibaseRegistrationNumber": query.did,
                "sireName": query.sname,
                "damName": query.dname
            });

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            const BreedHorses = await fetch(`${API_URL}/BreedHorses/v2/ProgenySearch`, requestOptions);
            result = await BreedHorses.json();

            if (BreedHorses.status === 401) {
                dispatch(logout());
            }
            setBreedHorsesList(result.successData);
        } else {
            requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const BreedHorses = await fetch(`${API_URL}/BreedHorses?orderBy=Id&orderByDesc=true&page=${page}&size=${size}`, requestOptions);
            result = await BreedHorses.json();

            if (BreedHorses.status === 401) {
                dispatch(logout());
            }
        }

        // setCurrentPage(result.successData.currentPage);
        setCurrentPage(page);
        setTotalPages(result.successData.totalPages);
        setTotalItems(result.successData.totalItems);
        setBreedHorsesList(result.successData.result);
        setLoading(false);

        //console.log('result', result);
        //console.log('successData', result.successData.result);
    }

    useEffect(() => {
        getBreedHorses(currentPage, pageSize)
    }, [])


    const handlePagination = (page, size) => {
        setCurrentPage(page);
        setPageSize(size)
        getBreedHorses(page, size);
    }



    return (
        <>
            <Layout>
                <Modal show={show} onClose={() => setShow(false)} />
                <div className='bg__area__content'>
                    <div className='container'>
                        <div className='py-3'>
                            <div className='page_breadcum'>
                                <ul>
                                    <li style={{
                                        color: 'rgba(140, 140, 140, 1)',
                                        fontSize: '14px',
                                        fontWeight: '400'

                                    }}> <a href="">
                                            <HiOutlineHome
                                                style={{
                                                    color: 'rgba(140, 140, 140, 1)',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    marginTop: '-4px'
                                                }}
                                            /> Breeding /</a> </li>
                                    <li style={{
                                        color: 'rgba(140, 140, 140, 1)',
                                        fontSize: '14px',
                                        fontWeight: '400'

                                    }} className='active text-dark'> <a href="">Breed Horses </a> </li>
                                </ul>
                            </div>

                            <div className='table__header shadow-sm'>
                                <h3 className='py-2' style={{
                                    fontSize: '36px'
                                }}>Breed Horses</h3>
                                <div className='d-flex justify-content-between table__header__row '>
                                    <div className='d-flex align-items-center mb-2 table__left_searchbtn gap-1'>
                                        <input type="text" className='table_search_input form-control flex-md-grow-3' placeholder='Search by sire or dam name' />
                                        <button className="btn btn-primary" style={{ fontSize: '14px', fontWeight: '400', lineHeight: '22px', marginRight: '5px' }}>Search</button>
                                    </div>
                                    <div>
                                        <button className='btn btn-default mx-2 border'> <i className="fas fa-filter"></i> Highest to Low</button>
                                        <Link to={'/events'}><button className='btn btn-outline-primary'>Add to Event</button></Link>
                                    </div>
                                </div>

                                <div className='table-responsive  mt-1'>
                                    <table className='table table-responsive'>
                                        <tr className='table__head__row'>
                                            <th className='table__tdp' style={{ width: '48px' }}>
                                                <input type="checkbox" />
                                            </th>
                                            <th className='table__tdp' style={{ width: '147.57px' }}>Sire & Dam Name</th>
                                            <th style={{ width: '147.57px' }}>New Horse</th>
                                            <th style={{ width: '147.57px' }}>Dirt <PiCaretUpDownFill /> </th>
                                            <th style={{ width: '147.57px' }}>Turf <PiCaretUpDownFill /> </th>
                                            <th style={{ width: '147.57px' }}>Synth <PiCaretUpDownFill /> </th>
                                            <th style={{ width: '147.57px' }}>Wet <PiCaretUpDownFill /> </th>
                                            <th style={{ width: '147.57px' }}>Notes</th>
                                            <th style={{ width: '213px' }}>Action</th>
                                            {/* <th className='table__tdrh align-right'>Action</th> */}
                                        </tr>

                                        {
                                            loading ? <>
                                            </> : <>
                                                {
                                                    breedHorsesList == '' ?
                                                        <tr className='table__row table__row_content'>
                                                            <td className='text-center' colSpan={9}>
                                                                <div className='w-100 d-flex justify-content-center'>
                                                                    <img src={`${baseUrl}/assets/img/icon/no_data_found.svg`} alt="" style={{ width: '150px' }} />
                                                                </div>
                                                            </td>
                                                        </tr> : <>
                                                            {breedHorsesList.map((item, index) => (
                                                                <>
                                                                    <ViewBredRow key={index} item={item} setShow={setShow} />
                                                                </>
                                                            ))}
                                                        </>
                                                }
                                            </>
                                        }
                                    </table>
                                </div>
                                <div className="table__pagination mt-3">
                                    <Pagination
                                        total={totalItems}
                                        onChange={(page, pageSize) => handlePagination(page, pageSize)}
                                        defaultPageSize={pageSize}
                                        defaultCurrent={currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </>
    )
}

export default EventBreed
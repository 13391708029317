/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Select } from 'antd';
import Swal from "sweetalert2";

import Layout from "../Layouts/Layout";
import Modal from "../modal/Modal";
import NotesModal from "../modal/NotesModal";
import queryString from "query-string";
import { API_URL } from "../../config/config";

import { HiOutlineHome } from "react-icons/hi";
import { logout } from "../../features/slices/authslice";
import { AiFillFilter } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { BiTrashAlt } from "react-icons/bi";
import { MdClear } from "react-icons/md";
import { JsonRequestBuilder } from '../../common/http-builder';
import debounce from 'lodash/debounce';
import { useRef } from 'react';
import HorseTableView from "../HorseTableView";
import { set } from "mobx";

class PaginationState {
  currentPage = 1;
  pageSize = 10;
}

class SortState {
  sortBy = "";
  sortDesc = false;
}

const BreedHorses = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const debounceTimeout = 800;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadArchive, setLoadArchive] = useState(false);
  const [loadDel, setLoadDel] = useState(false);
  const [searchQuery, setSearchQuery] = useState(false);
  const [tabActive, setTabActive] = useState(1);
  const [showFavoriteButton,] = useState(false);
  const [, showSelectCheckBox] = useState(true);
  const [idFieldName, setIdFieldName] = useState("id");

  const [paginationArchive, setPaginationArchive] = useState(new PaginationState());
  const [sortArchive, setSortArchive] = useState({
    sortBy: "id",
    sortDesc: true
  });
  const [recordCountArchive, setRecordCountArchive] = useState({});

  const [paginationAll, setPaginationAll] = useState(new PaginationState());
  const [sortAll, setSortAll] = useState({
    sortBy: "id",
    sortDesc: true
  });
  const [recordCountsAll, setRecordCountsAll] = useState({
    totalItems: 0,
    totalPages: 1
  });
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedAllArchive, setCheckedAllArchive] = useState(false);

  const [breedHorsesList, setBreedHorsesList] = useState([]);

  const [horses, setHorses] = useState([]);
  const [horsesArchive, setHorsesArchive] = useState([]);
  const [horsesEdit, setHorsesEdit] = useState([]);

  const [activeData, setActiveData] = useState(null);
  const [archiveData, setArchiveData] = useState([]);

  const [searchErr, setSearchErr] = useState(false);

  const [searchHorseName, setSearchHorseName] = useState("");
  const [searchHorseCommand, setSearchHorseCommand] = useState(null);

  /* Notes*/
  const [noteShow, setNoteShow] = useState(false);
  /* Notes*/

  const updateSearch = (e) => {
    setSearchHorseName(e.target.value);
  };

  const fetchData = async (request) => {
    console.log('fetchData', request.url, request.requestInit);
    const result = await fetch(request.url, request.requestInit);
    const data = await result.json();
    //console.log("result", result);
    //console.log("data", data.successData);

    if (result.status === 401) {
      dispatch(logout());
    }

    if (data.isSuccess === false) {
      request.failureCb && request.failureCb();
    } else {
      request.successCb && request.successCb(data.successData);
    }

    return { isSuccess: data.isSuccess, data: data.successData };
  }

  const archivePath = `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=true&`;
  const allPath = `${API_URL}/BreedHorses/index?filterBy=isArchived&filterValue=false&`;

  const buildArchiveFilters = () => {
    let f = searchHorseName.length > 0 ? [{ fieldName: "sireOrDamName", filterValue: searchHorseName, valueType: "s" }] : [];
    f = [...f, { fieldName: "isArchived", filterValue: true, valueType: "u"  }];
    return f;
  }

  const buildActiveFilters = () => {
    let f = searchHorseName.length > 0 ? [{ fieldName: "sireOrDamName", filterValue: searchHorseName, valueType: "s"  }] : [];
    f = [...f, { fieldName: "isArchived", filterValue: false, valueType: "u"  }];
    return f;
  }

  const buildDataArgs = (pagination, sort, filters) => {

    //add values to filters
    const sortArray = sort.sortBy.length > 0 ? [{ fieldName: sort.sortBy, direction: sort.sortDesc ? "d" : "a" }] : [];
    // console.log({ sortArray, filters, pagination });
    return {
      sort: sortArray,
      filter: filters,
      page: pagination.currentPage,
      pageSize: pagination.pageSize
    }
  }

  const calcTotalPages = (totalItems, pageSize) =>
    Math.ceil((totalItems || 1) / (pageSize || 1))


  const archiveFetchRef = useRef(0);
  const getArchivedHorses = () => {
    setLoading(true);
    setArchiveData([]);
    archiveFetchRef.current += 1;
    const fetchId = archiveFetchRef.current;

    // add the isArchived Filter
    const filters = buildArchiveFilters();
    const dataArgs = buildDataArgs(paginationArchive, sortArchive, filters);
    const request = (new JsonRequestBuilder())
      .init()
      .withUrl(archivePath)
      .withBody(dataArgs)
      .useMethod("POST")
      .withAuth()
      .get();

    (fetchData(request)).then((response) => {
      console.log('1');
      if (fetchId !== archiveFetchRef.current) {
        // for fetch callback order
        return;
      }

      if (!response.isSuccess) {
        setActiveData({});
        return;
      }

      const result = response.data;
      setArchiveData(result.data);
      const totalPages = calcTotalPages(result.totalItems, paginationArchive.pageSize);
      const newVal = { totalItems: result.totalItems, totalPages }
      setRecordCountArchive(newVal);
    }).finally(() => setTimeout(() => {
      setLoading(false);
    }, 2000));
  }

  const activeFetchRef = useRef(0);
  const getBreedHorses = () => {
console.log('3');

    setLoading(true);
    setActiveData(null);
    activeFetchRef.current += 1;
    const fetchId = activeFetchRef.current;
    const filters = buildActiveFilters();
    const dataArgs = buildDataArgs(paginationAll, sortAll, filters);
    const request = (new JsonRequestBuilder())
      .init()
      .withUrl(allPath)
      .withBody(dataArgs)
      .useMethod("POST")
      .withAuth()
      .get();

    (fetchData(request)).then((response) => {
      console.log('2');
      if (fetchId !== activeFetchRef.current) {
        // for fetch callback order
        return;
      }

      if (!response.isSuccess) {
        setActiveData({});
        return;
      }

      const result = response.data;
      setActiveData(result.data);
      const totalPages = calcTotalPages(result.totalItems, paginationAll.pageSize);
      const newVal = { totalItems: result.totalItems, totalPages };
      setRecordCountsAll(newVal);
    }).finally(() => setTimeout(() => {
      setLoading(false);
    }, 2000));
  }

  useEffect(() => {
    debounce(() => {
      console.log('4');
      getBreedHorses();getArchivedHorses();

      localStorage.setItem("horses", JSON.stringify([]));
    }, 300)();
  }, [sortAll, paginationAll]);

/*  useEffect(() => {
    debounce(async () => {
      console.log('5');
      getBreedHorses();
      getArchivedHorses();
    }, debounceTimeout)();
  }, [searchHorseCommand]);*/

/*  useEffect(() => {
    console.log('12');
    debounce(async () => {
      getArchivedHorses();
    }, 300)();
  }, [sortArchive, paginationArchive]);*/

/*  useEffect(() => {
    console.log('6')
    setLoading(true);
    getBreedHorses();
    localStorage.setItem("horses", JSON.stringify([]));
  }, []);*/

  const handleSingleClick = (id) => {
    if (horses.includes(id)) {
      setHorses(horses.filter((item) => item !== id));
    } else {
      setHorses((olddata) => [...olddata, id]);
    }
    setSearchHorseCommand(new Date());
  };

  const handleArchiveSingleClick = (id) => {
    if (horsesArchive.includes(id)) {
      setHorsesArchive(horsesArchive.filter((item) => item !== id));
    } else {
      setHorsesArchive((olddata) => [...olddata, id]);
    }
    setSearchHorseCommand(new Date());
  };

  useEffect(() => {
    if (checkedAllArchive) {
      const horsesList = archiveData.map((item) => item.id);
      setHorsesArchive(horsesList);
    } else {
      setHorsesArchive([]);
    }
  }, [checkedAllArchive]);

  useEffect(() => {
    if (checkedAll) {
      const horsesList = activeData.map((item) => item.id);
      setHorses(horsesList);
    } else {
      setHorses([]);
    }
  }, [checkedAll]);

  const handleEditClose = (cancelled, horse) => {
    setShow(false);
    if (!cancelled && horse) {

      // copy value to the activeData
      const index = activeData.findIndex((item) => item.id === horse.id);
      if (index >= 0) {
        activeData[index] = { ...activeData[index], ...horse };
        setActiveData([...activeData]);
      }
      // copy value to the archiveData
      const indexArchive = archiveData.findIndex((item) => item.id === horse.id);
      if (indexArchive >= 0) {
        archiveData[indexArchive] = { ...archiveData[index], ...horse };
        setArchiveData([...archiveData]);
      }
    }
  };

  const handleAddToEvent = () => {
    const data = horses;
    if (data == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
    } else {
      // cookie.set("horses", JSON.stringify(horses));
      localStorage.setItem("horses", JSON.stringify(data));
      navigate("/add-to-event");
    }
  };

  const handleUnArchiveClick = async () => {
    const data = horsesArchive;
    const shouldArchive = false;

    setLoadArchive(true);
    if (data == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
      setLoadArchive(false);
    } else {
      try {
        const newHorses = data.map((item) => { return { id: item, shouldArchive }; });

        const headers = new Headers();
        const auth = JSON.parse(localStorage.getItem("auth"));
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
            horses: newHorses,
          }),
          headers: headers,
          redirect: 'follow'
        };

        const addArchive = await fetch(`${API_URL}/horse/unarchive`, requestOptions);
        const archiveResult = await addArchive.json();
        if (addArchive.status === 401) {
          dispatch(logout());
        } else {
          if (archiveResult.isSuccess == true) {
            Swal.fire({
              icon: 'success',
              title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Been Unarchived Successfully!`,
            });

            setHorsesArchive([]);
            console.log('10');
            getBreedHorses();
            getArchivedHorses();
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    setLoadArchive(false);

  };

  const handleArchive = async (isArchived, data) => {
    setLoadArchive(true);
    if (data == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
      setLoadArchive(false);
    } else {
      try {
        const newHorses = horses.map((item) => { return { id: item, shouldArchive: isArchived }; });

        const headers = new Headers();
        const auth = JSON.parse(localStorage.getItem("auth"));
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify({
            horses: newHorses,
          }),
          headers: headers,
          redirect: 'follow'
        };

        const addArchive = await fetch(`${API_URL}/horse/archive`, requestOptions);
        const archiveResult = await addArchive.json();
        if (addArchive.status === 401) {
          dispatch(logout());
        } else {
          if (archiveResult.isSuccess == true) {
            console.log('11');
            getBreedHorses();
            getArchivedHorses();
            Swal.fire({
              icon: 'success',
              title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Been ${isArchived ? 'Archived' : 'Unarchived'} Successfully!`,
            });

            if (isArchived == true) {
              setHorses([]);
            } else {
              setHorsesArchive([]);
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    setLoadArchive(false);
  };


  const handleDelete = async () => {
    setLoadDel(true);
    if (horses == "") {
      Swal.fire({
        icon: "error",
        title: "Please Select Horse!",
      });
    } else {
      Swal.fire({
        title: 'Are You Sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete It!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const auth = JSON.parse(localStorage.getItem("auth"));
            const headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${auth.token}`);

            const requestOptions = {
              method: 'POST',
              body: JSON.stringify(horses),
              headers: headers,
              redirect: 'follow'
            };

            const HorseDelete = await fetch(`${API_URL}/Horse/delete-multiple`, requestOptions);
            const deleteResult = await HorseDelete.json();

            console.log({ deleteResult });

            if (HorseDelete.status === 401) {
              dispatch(logout());
            } else {
              if (deleteResult.isSuccess == true) {
                console.log('12');
                getBreedHorses();
                Swal.fire({
                  icon: 'success',
                  title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Been Deleted Successfully!`,
                })
                setHorses([]);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `${horses.length > 1 ? 'Horses' : 'Horse'} Has Not Been Deleted Successfully!`,
                })
              }
            }
          } catch (err) {
            console.log(err);
          }

        }
      })
    }
    setTimeout(() => {
      setLoadDel(false);
    }, 2500)
  };

  const resetPaginations = () => {
    setPaginationAll({ ...paginationAll, currentPage: 1 });
    setPaginationArchive({ ...paginationArchive, currentPage: 1 });
  }

  const clearAll = () => {
    setSortAll({ sortBy: "Id", sortDesc: true });
    setSortArchive({ sortBy: "Id", sortDesc: true });
    resetPaginations();
    setSearchHorseName("");
  };

  const handleAllSortClick = (sortName) => {
    if (sortName === sortAll.sortBy) {
      setSortAll({ ...sortAll, sortDesc: !sortAll.sortDesc });
    } else {
      setSortAll({ sortBy: sortName, sortDesc: false });
    }
  }

  const handleArchiveSortClick = (sortName) => {
    if (sortName === sortArchive.sortBy) {
      setSortArchive({ ...sortArchive, sortDesc: !sortArchive.sortDesc });
    } else {
      setSortArchive({ sortBy: sortName, sortDesc: false });
    }
  }
  return (
    <>
      <Layout>
        <Modal
          show={show}
          onClose={handleEditClose}
          setShow={setShow}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
        />
        <NotesModal
          noteShow={noteShow}
          setNoteShow={setNoteShow}
          onClose={() => setNoteShow(false)}
          horsesEdit={horsesEdit}
          setHorsesEdit={setHorsesEdit}
        />
        <div className="bg__area__content">
          <div className="container">
            <div className="py-2">
              <div className="page_breadcum">
                <ul>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    <Link to="/breed-horses">
                      <HiOutlineHome
                        style={{
                          color: "rgba(140, 140, 140, 1)",
                          fontSize: "14px",
                          fontWeight: "400",
                          marginTop: "-4px",
                        }}
                      />{" "}
                      Breeding /
                    </Link>
                  </li>
                  <li
                    style={{
                      color: "rgba(140, 140, 140, 1)",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                    className="active text-dark"
                  >
                    {" "}
                    <a href="">
                      {searchQuery ? "Bred Results" : "Bred Horses"}
                    </a>{" "}
                  </li>
                </ul>
              </div>

              <div className="table__header shadow-sm">
                <h3 className="py-2">
                  {searchQuery ? "Bred Results" : "Bred Horses"}
                </h3>


                <div className="table__Tabs">
                  <div
                    className={`single-tab ${tabActive == 1 ? "active" : ""}`}
                    onClick={() => setTabActive(1)}
                  >
                    All Horses ({recordCountsAll.totalItems})
                  </div>
                  <div
                    className={`single-tab ${tabActive == 2 ? "active" : ""}`}
                    onClick={() => setTabActive(2)}
                  >
                    Archive Horses ({recordCountArchive.totalItems})
                  </div>
                </div>
                <div className="row d-flex justify-content-between table__header__row ">
                  <div className="col-sm-12 col-xxl-6 d-flex align-items-center mb-2 table__left_searchbtn gap-2">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 mb-2">
                        <form
                          className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                          onSubmit={(e) => {
                            e.preventDefault();
                            setSearchHorseCommand(new Date());
                            resetPaginations();
                          }}
                        >
                          <div className="table_search_input input-group form-group position-relative">
                            <input
                              type="text"
                              className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                              name="name"
                              value={searchHorseName}
                              onChange={updateSearch}
                              placeholder="Search by sire or dam name"
                              autocomplete="off"
                            />
                            <span
                              className="input-group-append position-absolute"
                              style={{
                                zIndex: "10",
                                marginTop: "-2px",
                                right: "-7px",
                              }}
                            >
                              {searchHorseName.length > 0 ? (
                                <div className="input-group-text border-0 bg-transparent ml-n5">
                                  <span
                                    className=""
                                    style={{
                                      cursor: "pointer",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    onClick={clearAll}
                                  >
                                    <MdClear className="clear_logo" />
                                  </span>
                                </div>
                              ) : null}
                            </span>
                          </div>
                          <button
                            className="btn btn-primary"
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              lineHeight: "22px",
                              marginRight: "5px",
                            }}
                          >
                            Search
                          </button>
                        </form>
                      </div>
                      <div className="col-sm-12 col-md-6" style={{ marginTop: "-6px" }}>
                        <div className="sort_button mt-2 gap-1" style={{ float: "left" }}>
                          <div
                            className="dropdown"
                            style={{ width: "155px", height: "40px" }}
                          >
                            <button
                              className="btn btn-default mx-2 mb-2 border d-flex align-items-center justify-content-center text-center"
                              type="button"
                              data-bs-toggle="dropdown"
                              style={{ width: "155px", height: "40px" }}
                            >
                              <AiFillFilter
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                  marginRight: "5px",
                                }}
                              />
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "22px",
                                }}
                              >
                                Sort by Date
                              </span>
                            </button>
                            <ul
                              className="dropdown-menu border-start border-end border-bottom border-top-0"
                              style={{ width: "136px !important" }}
                            >
                              <li
                                onClick={(e) => {
                                  setSortAll({
                                    sortBy: "sireFoalingDate",
                                    sortDesc: true
                                  });
                                  setSortArchive(sortAll);
                                }}
                              >
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Sire Oldest to Newest
                                </a>
                              </li>
                              <li
                                onClick={(e) => {
                                  setSortAll({
                                    sortBy: "sireFoalingDate",
                                    sortDesc: false
                                  });
                                  setSortArchive(sortAll);
                                }}
                              >
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Sire Newest to Oldest
                                </a>
                              </li>
                              <li
                                onClick={(e) => {
                                  setSortAll({
                                    sortBy: "damFoalingDate",
                                    sortDesc: true
                                  });
                                  setSortArchive(sortAll);
                                }
                                }
                              >
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Dam Oldest to Newest
                                </a>
                              </li>
                              <li
                                onClick={(e) => {
                                  setSortAll({
                                    sortBy: "damFoalingDate",
                                    sortDesc: false
                                  });
                                  setSortArchive(sortAll);
                                }}
                              >
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                  }}
                                >
                                  Dam Newest to Oldest
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-xxl-6 d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center gap-2" style={{ float: "left" }}>

                      {
                        tabActive == 1 ? <>
                          {
                            loadArchive ?
                              <span
                                style={{
                                  color: `#000`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-outline-primary" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-outline-primary"
                                onClick={() => handleArchive(true, horses)}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                Archive ({horses.length})
                              </span>
                          }
                        </> : null
                      }
                      {
                        tabActive == 2 ? <>
                          {
                            loadArchive ?
                              <span
                                style={{
                                  color: `#000`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-outline-primary" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-outline-primary"
                                onClick={() => handleUnArchiveClick()}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                Unarchive ({horsesArchive.length})
                              </span>
                          }
                        </> : null
                      }
                      {
                        tabActive == 1 ? <>
                          {
                            loadDel ?
                              <span
                                style={{
                                  color: `white`,
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                                className="btn btn-danger" disabled>
                                Processing...
                              </span> :
                              <span
                                className="btn btn-danger d-flex align-items-center justify-content-center text-center"
                                onClick={handleDelete}
                                style={{
                                  width: "140px",
                                  height: "40px",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                }}
                              >
                                <BiTrashAlt
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    marginRight: "5px",
                                  }}
                                />
                                <span>
                                  Delete ({horses.length})
                                </span>
                              </span>
                          }
                        </> : null
                      }
                      <span
                        className="btn btn-primary"
                        onClick={() => handleAddToEvent(tabActive == 1 ? horses : horsesArchive)}
                        style={{
                          width: "140px",
                          height: "40px",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "24px",
                        }}
                      >
                        Add to Event
                      </span>
                    </div>
                  </div>
                </div>

                {
                  tabActive == 1 ? (
                    <div>
                      <HorseTableView
                        data={activeData}
                        baseUrl={allPath}
                        loading={loading}
                        setLoading={setLoading}
                        horses={horses}
                        setHorses={setHorses}
                        setShow={setShow}
                        noteShow={noteShow}
                        setNoteShow={setNoteShow}
                        horseEdit={horsesEdit}
                        setHorseEdit={setHorsesEdit}
                        className="table mt-1"
                        getData={getBreedHorses}
                        setPaginationState={setPaginationAll}
                        paginationState={paginationAll}
                        setSort={setSortAll}
                        sort={sortAll}
                        onSortClick={handleAllSortClick}
                        recordCount={recordCountsAll}
                        allowHorseEdit={true}
                        onSelectSingleClick={handleSingleClick}
                        showFavoriteButton={showFavoriteButton}
                        showSelectCheckBox={showSelectCheckBox}
                        setCheckedAll={setCheckedAll}
                        checkedAll={checkedAll}
                        idFieldName={idFieldName}
                      />
                    </div>
                  ) : (
                    //need to hack this because of a bug in the pager. Do not remove the span tag.
                    <div><span style={{ maxHeight: "1px", margin: "0" }}></span>
                      <HorseTableView
                        data={archiveData}
                        baseUrl={allPath}
                        loading={loading}
                        setLoading={setLoading}
                        horses={horsesArchive}
                        setHorses={setHorsesArchive}
                        setShow={setShow}
                        noteShow={noteShow}
                        setNoteShow={setNoteShow}
                        horseEdit={horsesEdit}
                        setHorseEdit={setHorsesEdit}
                        className="table mt-1"
                        getData={getArchivedHorses}
                        setPaginationState={setPaginationArchive}
                        paginationState={paginationArchive}
                        setSort={setSortArchive}
                        sort={sortArchive}
                        onSortClick={handleArchiveSortClick}
                        recordCount={recordCountArchive}
                        allowHorseEdit={true}
                        onSelectSingleClick={handleArchiveSingleClick}
                        showFavoriteButton={showFavoriteButton}
                        showSelectCheckBox={showSelectCheckBox}
                        setCheckedAll={setCheckedAllArchive}
                        checkedAll={checkedAllArchive}
                        idFieldName={idFieldName}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BreedHorses;

import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authApi } from './api/authApi'
import authsliceReducer from './slices/authslice'
import { horseApi } from './api/horseApi'
import { eventApi } from './api/eventApi'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [authApi.reducerPath]: authApi.reducer,
    [horseApi.reducerPath]: horseApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    auth: authsliceReducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authApi.middleware, horseApi.middleware,eventApi.middleware]),
    devTools: true
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
import React from 'react'
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

const ColumnSortIcon = ({ sortName, sortState }) => {
  // console.log('sortIcon', select, sort)
  const defaultUpStyle = { color: 'rgb(123, 123, 123)', fontSize: '18px', marginBottom: '-5px' }
  const defaultDownStyle = { color: 'rgb(123, 123, 123)', fontSize: '18px', marginTop: '-5px' }
  const arrowColor = 'black';

  return (
    <span className="sort">
      {sortName !== sortState?.sortBy ? (
        <>
          <TiArrowSortedUp style={defaultUpStyle} />
          <TiArrowSortedDown style={defaultDownStyle} />
        </>
      ) : (
        <>
          {sortState.sortDesc ? (
            <>
              <TiArrowSortedUp style={defaultUpStyle} />
              <TiArrowSortedDown style={{ ...defaultDownStyle, color: arrowColor }} />
            </>
          ) : (
            <>
              <TiArrowSortedUp style={{ ...defaultUpStyle, color: arrowColor }} />
              <TiArrowSortedDown style={defaultDownStyle} />
            </>
          )}
        </>
      )}
    </span>
  );
}

const SortableColumn = ({ columnTitle, sortName, sortState, setSortState, onSortClickHandler, ...props }) => {
  return (<th
    onClick={(e) => {
      onSortClickHandler(sortName)

    }
    }
    {...props}
  >
    <span
      className="d-flex align-items-center justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "45px",
        backgroundColor: "#FFF0",

      }}
    >
      {columnTitle}      <ColumnSortIcon sortName={sortName} sortState={sortState} />

    </span>
  </th>);
}
export default SortableColumn;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react'
import Logo from './img/logo.png'
import { Link, useParams, useLocation } from "react-router-dom";
import Nav from './Nav';
import { logout } from '../features/slices/authslice';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const Header = () => {

  const { id } = useParams();
  const location = useLocation();
  const [dropDown, setDropDown] = useState(false)
  const menuRef = useRef()
  const dispatch = useDispatch();
  const auth = JSON.parse(localStorage.getItem("auth"));

  const authCheck = async () => {
    const expiryDate = Date.parse(new Date(auth?.expiry));
    const currentDate = Date.parse(new Date());
    try {
      if (auth?.token) {

        if (expiryDate > currentDate) {

          // var myHeaders = new Headers();
          // myHeaders.append("Content-Type", "application/json");
          // //myHeaders.append("Authorization", `Bearer ${auth.token}`);
          // const requestOptions = {
          //     method: 'POST',
          //     body: JSON.stringify({
          //         email: auth.user,
          //         refreshToken: auth?.refreshToken,
          //     }),
          //     headers: myHeaders,
          //     redirect: 'follow'
          // };
          // const refresh = await fetch(`${API_URL}/User/v2/refresh-token`, requestOptions);
          // const result = await refresh.json();
          // console.log({result});

        } else {
          dispatch(logout());
        }
      } else {
        dispatch(logout());
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (auth !== null) {
      authCheck();
    }

    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  return (
    <>
      <div className='equi__nav'>
        <div className='container nav__logo__area'>
          <div className='nav__left'>
            <Link to={`${auth == null ? `/login` : `/breed-horses`}`}>
              <img src={Logo} width={212} />
            </Link>
            <div className='logo__text'>Sales Assistant</div>
          </div>

          {location?.pathname !== '/login' && location?.pathname !== '/forgot-password' && location?.pathname !== `/share/${id}` ? (
            <Nav />
          ) : ''}

          <div className='nav__right'>
            {location?.pathname !== '/login' && location?.pathname !== '/forgot-password' && location?.pathname !== `/share/${id}` ?
              <div className="menu__drop" ref={menuRef}>
                <button
                  className="btn btn-default rounded border text-primary d-flex align-items-center profile-toggle gap-2"
                  type="button"
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16.8px',
                    padding: '8px 16px 8px 16px',
                    gap: '8px',
                    color: 'rgba(36, 76, 206, 1) !important',
                  }}
                  onClick={() => setDropDown(true)}
                >
                  Ryan D.
                  <i className="fas fa-chevron-circle-down"></i>
                </button>

                <div className={`login__child ${dropDown ? 'd-block' : 'd-none'}`}>
                  {/* <a href="" >Profile</a> */}
                  <a onClick={() => dispatch(logout())}>Sign Out</a>
                </div>

              </div>
              :

              (

                <a
                  className="btn btn-default rounded border text-primary d-flex align-items-center"
                  href="/login"
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '16.8px',
                    padding: '8px 16px 8px 16px',
                    gap: '8px',
                  }}
                  ref={menuRef}
                >
                  LOG IN
                </a>
              )
              // <Link to="/login" className='btn btn-default rounded-pill border'>SIGN IN</Link>

            }



          </div>
        </div>
      </div>

      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
    </>

  )
}

export default Header

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import { AiOutlineEdit } from "react-icons/ai";

import { baseUrl, API_URL } from "../config/config";
import { logout } from "../features/slices/authslice";
import SortIcon from "./icons/SortIcon";
import {formatNumber, isCpuSelectedStyle} from "../common/style-functions";

const ViewEventFavoriteRow = (props) => {
    const {
        item,
        setShow,
        noteShow,
        setNoteShow,
        horses,
        setHorses,
        pageSize,
        currentPage,
        horsesEdit,
        setHorsesEdit,
        getEventDetails,
        handlePagination,
        breedStatus,
        setBreedStatus,
        getEventFavorite,
        getEventSearchPage,
        onEventFavoriteClick
    } = props;

    const dispatch = useDispatch()

    const [showBread, setShowBread] = useState(false);
    const [favoriteLoad, setFavoriteLoad] = useState(false);
    const [checkboxChek, setCheckboxChek] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const handleShow = (value) => {
        setShowBread(showBread === value ? 0 : value);
    };

    //console.log("item-----", item);

    const handleNoteShow = (value) => {
        setHorsesEdit(item);
        setNoteShow(true);
    };

    // console.log('item.geneticStrengthRatings', item.geneticStrengthRatings);

    const [sort, setSort] = useState(true);
    const [gsrSort, setGsrSort] = useState("");
    const [gsrList, setGSRList] = useState(item.geneticStrengthRatings);

    const getGsrDirt = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.dirt - b.dirt));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.dirt - a.dirt));
        }
    };
    const getGsrTurf = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.turf - b.turf));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.turf - a.turf));
        }
    };
    const getGsrSynth = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.synthetic - b.synthetic));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.synthetic - a.synthetic));
        }
    };
    const getGsrWet = (info) => {
        let newSort;

        if (gsrSort == info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        setGsrSort(info);
        if (newSort == true) {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => a.wet - b.wet));
        } else {
            setGSRList(item.geneticStrengthRatings.sort((a, b) => b.wet - a.wet));
        }
    };



    const FavoriteHandler = async (horseId, isFavorite) => {
        onEventFavoriteClick(horseId, isFavorite);
    };

    return (
        <>
            <tr className="table__row table__row_content">
                <td className="px-2 py-3">
                    {favoriteLoad ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    ) : (
                        <>
                            {item.isFavorite ? (
                                <i
                                    className="fas fa-star star-fill"
                                    onClick={() => { setBreedStatus('favorite'); FavoriteHandler(item.id, false); }}
                                    style={{ cursor: "pointer" }}
                                ></i>
                            ) : (
                                <i
                                    className="far fa-star"
                                    onClick={() => { setBreedStatus('favorite'); FavoriteHandler(item.id, true); }}
                                    style={{ cursor: "pointer" }}
                                ></i>
                            )}
                        </>
                    )}
                </td>
                <td className="py-3">{item.eventHorseId}</td>
                <td className="py-3">
                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center" style={isCpuSelectedStyle(item.isSireCpuSelected)}>
                            {item.sireName}
                        </div>
                        {item.isSireCpuSelected == null ? null : (
                            <>
                                {item.isSireCpuSelected == true ? (
                                    <div className="w-100 d-flex align-items-center mt-2">
                                        <img
                                            src="/assets/img/icon/cpu_picked.svg"
                                            style={{
                                                width: "65.62px",
                                                height: "16px",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </td>
                <td className="py-3">
                    <div className="w-100">
                        <div className="w-100 d-flex align-items-center" style={isCpuSelectedStyle(item.isSireCpuSelected)}>
                            {item.damName}
                        </div>
                        {item.isDamCpuSelected == null ? null : (
                            <>
                                {item.isDamCpuSelected == true ? (
                                    <div className="w-100 d-flex align-items-center mt-2">
                                        <img
                                            src="/assets/img/icon/cpu_picked.svg"
                                            style={{
                                                width: "65.62px",
                                                height: "16px",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </td>
                <td className="py-3">{item.horseName}</td>
                <td className="py-3" style={{ textTransform: "capitalize" }}>
                    {item?.sex?.length > 1 ? `${item?.sex?.substring(0, 1)}` : item?.sex}
                </td>
                <td className="py-3">{item.hipNumber}</td>
                <td>{item.gsrDirt}</td>
                <td>{item.gsrTurf}</td>
                <td>{item.gsrSynth}</td>
                <td>{item.gsrWet}</td>
                <td className="py-3">${formatNumber(item.price)}</td>
                <td className="py-3">
                    <div
                        className="table__comment"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setHorsesEdit(item);
                            handleNoteShow();
                        }}
                    >
                        <img
                            src="/assets/img/icon/chat.svg"
                            style={{
                                width: "21.68px",
                                height: "21.68px",
                            }}
                        />
                        <span className="comment__badge">{item.horseNotes.length}</span>
                    </div>
                </td>
                <td className="py-3">
                    <div className="d-flex justify-content-center gap-2">
                        <button
                            className="btn btn-default border site-s-font d-flex justify-content-center gap-3"
                            type="button"
                            style={{
                                width: "76px",
                                height: "32px",
                                padding: "5px, 12px, 5px, 12px",
                                gap: "6px",
                            }}
                            onClick={() => handleShow(1)}
                        >
                            <span
                                style={{
                                    marginTop: "-2px",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                }}
                            >
                                GSR
                            </span>
                            <i className="fas fa-caret-down" style={{ marginTop: "2px" }}></i>
                        </button>
                        <button
                            className="btn btn-default border site-s-font text-primary d-flex justify-content-center"
                            style={{
                                width: "73px",
                                height: "32px",
                                padding: "5px, 12px, 5px, 12px",
                                gap: "6px",
                            }}
                            onClick={() => {
                                setShow(true);
                                setHorsesEdit(item);
                            }}
                        >
                            <AiOutlineEdit style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </td>
            </tr>
            {showBread ? (
                <>
                    {item.geneticStrengthRatings == "" ? (
                        <tr className="bg-gray" style={{ height: "37px" }}>
                            <td className="text-center" colSpan={14}>
                                No Data Found
                            </td>
                        </tr>
                    ) : (
                        <>
                            <tr
                                className=""
                                style={{ height: "37px", backgroundColor: `#E5E5E5` }}
                            >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td colSpan={2}>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                        }}
                                    >
                                        Distance/Furlongs{" "}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrDirt(`Dirt-${item.id}`)}
                                    >
                                        Dirt{" "}
                                        {gsrSort == `Dirt-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Dirt-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrTurf(`Turf-${item.id}`)}
                                    >
                                        Turf{" "}
                                        {gsrSort == `Turf-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Turf-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrSynth(`Synth-${item.id}`)}
                                    >
                                        Synth{" "}
                                        {gsrSort == `Synth-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Synth-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td>
                                    <span
                                        className="d-flex align-items-center justify-content-start"
                                        style={{
                                            width: "100%",
                                            height: "37px",
                                            backgroundColor: "#FFF0",
                                            marginLeft: "-7px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => getGsrWet(`Wet-${item.id}`)}
                                    >
                                        Wet{" "}
                                        {gsrSort == `Wet-${item.id}` ? (
                                            <SortIcon
                                                select={gsrSort == `Wet-${item.id}` ? true : false}
                                                sort={sort}
                                            />
                                        ) : (
                                            <SortIcon select={null} sort={sort} />
                                        )}
                                    </span>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {item.geneticStrengthRatings.map((item, index) => (
                                <>
                                    {item.distanceDisplay == "ALL" ? null : (
                                        <tr
                                            className=""
                                            style={{
                                                height: "37px",
                                                backgroundColor: `${index % 2 == 0 ? `#EFEFEF` : `#E5E5E5`
                                                    }`,
                                            }}
                                        >

                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colSpan={2}>{item.distanceDisplay}</td>
                                            <td>{item.dirt}</td>
                                            <td>{item.turf}</td>
                                            <td>{item.synthetic}</td>
                                            <td>{item.wet}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </>
            ) : null}

        </>
    );
};

export default ViewEventFavoriteRow
import HorseTableRowView from "./HorseTableRowView";
import TableLoading from "./TableLoading";
import HorseTableColumns from "./HorseTableColumns";
import { Pagination } from 'antd';



const HorseTableView = ({
    data,
    baseUrl,
    horses,
    setHorses,
    horseEdit,
    setHorseEdit,
    getData,
    handleFavoriteClick,
    noteShow,
    setNoteShow,
    setShow,
    paginationState,
    setPaginationState,
    setSort,
    sort,
    onSortClick,
    loading,
    recordCount,
    allowHorseEdit,
    onSelectSingleClick,
    showSelectCheckBox,
    setCheckedAll,
    checkedAll,
    showFavoriteButton,
    idFieldName,
    ...props }) => {

    const onPaginationClick = (page, size) => {
        const newFavVal = { ...paginationState, currentPage: page, pageSize: size };
        setPaginationState(newFavVal);
    }

    if (loading && !data) {
        return (
            <div>
                <div className="table-responsive table__fix_head mt-1">

                    <TableLoading />

                </div>
            </div>);
    }

    if (!data?.length) {
        return (
            <div>
                <div className="table-responsive table__fix_head mt-1">
                    <table {...props}>
                        <HorseTableColumns
                            sort={sort}
                            setSort={setSort}
                            onSortClick={onSortClick}
                            showSelectCheckBox={showSelectCheckBox}
                            setCheckedAll={setCheckedAll}
                            checkedAll={checkedAll}
                        />
                        <tr className="table__row table__row_content">
                            <td className="text-center" colSpan={15}>
                                <div
                                    className="w-100 d-flex justify-content-center"
                                    style={{ height: "55vh" }}
                                >
                                    <img
                                        src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                        alt=""
                                        style={{ width: "150px" }}
                                    />
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>);
    }
    return (
        <div>
            <div className="table-responsive table__fix_head mt-1">
                <table {...props}>
                    <HorseTableColumns
                        sort={sort}
                        setSort={setSort}
                        onSortClick={onSortClick}
                        showSelectCheckBox={showSelectCheckBox}
                        setCheckedAll={setCheckedAll}
                        checkedAll={checkedAll}
                        allowHorseEdit={allowHorseEdit}
                    />
                    {data.map((item, index) => (
                        <HorseTableRowView
                            key={index}
                            item={item}
                            setShow={setShow}
                            setNoteShow={setNoteShow}
                            horses={horses}
                            setHorses={setHorses}
                            setHorseEdit={setHorseEdit}
                            allowHorseEdit={allowHorseEdit}
                            showFavoriteButton={showFavoriteButton}
                            showSelectCheckBox={showSelectCheckBox}
                            onSelectSingleClick={onSelectSingleClick}
                            isSelected={horses.some((id) => id === item.id)}
                            handleFavoriteClick={handleFavoriteClick}
                            idFieldName={idFieldName}
                        />
                    ))}
                </table>
            </div>
            {
                !data ? null : (
                    <div className="table__pagination mt-3">
                        &nbsp;<Pagination
                            showSizeChanger
                            total={recordCount.totalItems}
                            onChange={onPaginationClick}
                            defaultPageSize={paginationState.pageSize}
                            defaultCurrent={paginationState.currentPage}
                        />
                    </div>

                )
            }
        </div >
    );
}

export default HorseTableView;
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
import React, { ReactDOM, useEffect, useState } from "react";
import { Link, useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Select, Pagination } from 'antd';
import Swal from "sweetalert2";

import Layout from "./Layouts/Layout";
import ShareModal from "./modal/ShareModal";
import Modal from "./modal/Modal";
import NotesModal from "./modal/NotesModal";
import ViewRow from "./ViewRow";
import { baseUrl, API_URL } from "../config/config";

import ViewEventRow from "./ViewEventRow";
import ViewEventFavoriteRow from "./ViewEventFavoriteRow";
import { logout } from "../features/slices/authslice";
import { AiFillFilter } from "react-icons/ai";
import { useDispatch } from "react-redux";
import SortIcon from "./icons/SortIcon";
import { MdClear } from "react-icons/md";
import TableLoading from "./TableLoading";
import EventHipNumberSearch from "./EventHipNumberSearch";
import { JsonRequestBuilder } from '../common/http-builder';
import { computed, makeObservable, observable, flow, flowResult, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import EventDetails from "./EventDetails";
import { SortOption, FilterOption, PaginationOptions, FetchOptions } from "../features/events/events-store";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { current } from "@reduxjs/toolkit";

const useSingleton = (cbArgs = {}, callBack = () => { }) => {
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    if (hasBeenCalled) return;
    callBack(cbArgs);
    setHasBeenCalled(true);
}

class PaginationState {
    currentPage = 1;
    pageSize = 10;
    totalItems = 1;
    totalPages = 1;
}

// current sort is a reference to the store sort options
const OrderIcon = observable(({ currentSort, field }) => {
    // console.log('sortIcon', select, sort)
    if (currentSort.field != field) return null;

    return (
        <span className="sort">
            {currentSort.order === "ASC" ? <TiArrowSortedUp style={{ color: `black`, fontSize: '18px', marginBottom: "-5px" }} /> :
                <TiArrowSortedDown style={{ color: `black`, fontSize: '18px', marginTop: "-5px" }} />}
        </span>
    )
});

const EventTableHeader = observable(({ sortOptions, field, label = "", sortable = false, ...props }) => {
    return (
        <th
            {...props}
        >{sortable ?
            <span
                className="d-flex align-items-center justify-content-start"
                style={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#FFF0",
                    marginLeft: "-7px",
                }}
            >
                {label}
                <OrderIcon
                    currentSort={sortOptions}
                    field={field}
                />
            </span> : label}
        </th>
    )
});

const EventView = observer(({ store }) => {

    const onSortClick = (e) => {
        store.sortOptions = { field: e.target.field, order: store.sortOptions.order == "ASC" ? "DESC" : "ASC" };
    };

    return (<div><div className="table-responsive table__fix_head mt-1">
        <table className="table mt-1">
            <tr className="table__head__row">
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "48px" }}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "60px", paddingLeft: "0px" }}
                    label="ID"
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "147.57px", paddingLeft: "0px" }}
                    label="Sire Name"
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "147.57px", paddingLeft: "0px" }}
                    label="Dam Name"
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "147.57px", paddingLeft: "0px" }}
                    label="New Horse"
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "60px", paddingLeft: "0px" }}
                    label="Sex"
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Hip"
                    sortable={true}
                    field="hipNumber"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Dirt"
                    sortable={true}
                    field="gsrDirt"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Turf"
                    sortable={true}
                    field="gsrTurf"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Synth"
                    sortable={true}
                    field="gsrSynth"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Wet"
                    sortable={true}
                    field="gsrWet"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    className="table__tdp"
                    style={{ width: "90px !important", paddingLeft: "0px" }}
                    label="Price"
                    sortable={true}
                    field="price"
                    onClick={onSortClick}
                />
                <EventTableHeader
                    label="Notes"
                    style={{ width: "70px", paddingLeft: "0px" }}
                />
                <EventTableHeader
                    label="Action"
                    style={{ width: "70px", paddingLeft: "0px" }}
                />
            </tr>
            {store.pendingRequests > 0 ? (
                <tr className="table__row table__row_content table-loading">
                    <td className="text-center" colSpan={14}>
                        <TableLoading />
                    </td>
                </tr>
            ) : (
                <>
                    {!store.horses && !store.horses.result ? (
                        <tr className="table__row table__row_content">
                            <td className="text-center" colSpan={14}>
                                <div
                                    className="w-100 d-flex justify-content-center"
                                    style={{ height: "55vh" }}
                                >
                                    <img
                                        src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                        alt=""
                                        style={{ width: "150px" }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <>
                            {/* {store.horses.result.map((item, index) => (
                                <ViewEventRow
                                    key={index}
                                    item={item}
                                    setShow={setShow}
                                    noteShow={noteShow}
                                    setNoteShow={setNoteShow}
                                    horses={horses}
                                    setHorses={setHorses}
                                    horsesEdit={horsesEdit}
                                    setHorsesEdit={setHorsesEdit}
                                    getEventDetails={getAllEventDetails}
                                    handlePagination={handleAllTabPagination}
                                    pageSize={paginationAll.pageSize}
                                    currentPage={paginationAll.currentPage}
                                    breedStatus={breedStatus}
                                    setBreedStatus={setBreedStatus}
                                    getEventFavorite={getFavoritesEventDetails}
                                    getEventSearchPage={getAllEventSearchPage}
                                    onEventFavoriteClick={handleAllEventFavoriteClick}
                                />
                            ))} */}
                        </>
                    )}
                </>
            )}
        </table>
    </div>
        <div className="table__pagination mt-3">
            {/* <Pagination id="allTab"
                total={paginationAll.totalItems}
                onChange={(page, pageSize) => handleAllTabPagination(page, pageSize)}
                defaultPageSize={paginationAll.pageSize}
                defaultCurrent={paginationAll.currentPage}
            /> */}
        </div></div>
    )
});

export const EventDetails2 = observer(({ allEventStore, favoritesEventStore }) => {

    const { id } = useParams();

    allEventStore.eventId = id;
    favoritesEventStore.eventId = id;
    allEventStore.path = `${API_URL}/Event/byid/${id}`;
    favoritesEventStore.path = `${API_URL}/Event/byid/${id}/favorites`;


    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showShare, setShowShare] = useState(false);
    const [viewRow, setViewRow] = useState(0);
    const [tabActive, setTabActive] = useState(1);

    const [paginationFavorite, setPaginationFavorite] = useState(new PaginationState());
    const [paginationAll, setPaginationAll] = useState(new PaginationState());

    const [horses, setHorses] = useState([]);
    const [horsesEdit, setHorsesEdit] = useState([]);

    const [favoritesHorses, setFavoritesHorses] = useState([]);
    const [favoritesHorsesEdit, setFavoritesHorsesEdit] = useState([]);

    const [eventDetails, setEventDetails] = useState([]);
    const [favoriteDetails, setFavoriteDetails] = useState([]);

    const [highestStatus, setHighestStatus] = useState(false);
    const [favoritesHighestStatus, setFavoritesHighestStatus] = useState(false);

    const [sort, setSort] = useState(true);
    const [favoritesSort, setFavoritesSort] = useState(true);

    const [sortPage, setSortPage] = useState(true);
    const [favoritesSortPage, setFavoritesSortPage] = useState(true);

    const [breedStatus, setBreedStatus] = useState("get");
    const [favoritesBreedStatus, setFavoritesBreedStatus] = useState("get");

    const [gsrSort, setGsrSort] = useState("");
    const [gsrFavoritesSort, setFavoritesGsrSort] = useState("");

    const [highestSort, setHighestSort] = useState("");
    const [favoritesHighestSort, setFavoritesHighestSort] = useState("");


    const [shareID, setShareID] = useState("");

    const [searchErr, setSearchErr] = useState(false);
    const [search, setSearch] = useState({
        name: "",
    });

    /* Notes*/
    const [noteShow, setNoteShow] = useState(false);
    /* Notes*/

    const [hipNumberErr, setHipNumberErr] = useState(false);

    const [hipNumbers, setHipNumbers] = useState([]);
    const [hipNumberOptions, setHipNumberOptions] = useState([]);
    const [hipNumbersLoading, setHipNumbersLoading] = useState(false);

    const updateSearch = (e) => {
        setSearch({ ...search, [e.target.name]: e.target.value });
    };

    const updateFilter = (newVal) => {
        if (newVal == "") {
            ClearAllHip();
        } else {
            setSearchParams(searchParams?.set('hipNumber', newVal));
        }
        handleHipNumberFilter(newVal);
    };

    const fetchData = async (url, successCb, failureCb) => {
        setLoading(true);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);
        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        const result = await fetch(url, requestOptions);
        const data = await result.json();
        //console.log("result", data);
        if (result.status === 401) {
            dispatch(logout());
        }

        if (result.isSuccess == false) {
            failureCb && failureCb();
        } else {
            successCb && successCb(data.successData);
        }

        setTimeout(() => {
            setLoading(false);
        }, 1200);

        return { isSuccess: result.isSuccess, data };
    }

    const favoritesPath = `${API_URL}/Event/byid/${id}/favorites`;
    const allPath = `${API_URL}/Event/byid/${id}`;

    const getAllEventDetails = async (page, size) => {
        setLoading(true);
        setBreedStatus("get");
        setHighestSort("");
        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?orderBy=Id&orderByDesc=true&${hips}page=${page}&size=${size}`
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    };

    const loadHipNumbers = async () => {
        setHipNumbersLoading(true);
        //console.log('fetching events', id);
        const url = `${API_URL}/event/${this.eventId}/hip-numbers`;
        const builder = new JsonRequestBuilder();
        const req = builder
            .init()
            .useMethod("GET")
            .withAuth()
            .withUrl(url)
            .get();

        await fetch(req.url, req.requestInit)
            .then((response) => response.json())
            .then((body) => {
                console.debug(body);
                return body.successData.map((h) => ({
                    label: h,
                    value: h,
                }));
            })
            .then((data) => setHipNumberOptions(data))
            .finally(() => setHipNumbersLoading(false));

        ;
    };

    const getFavoritesEventDetails = async (page, size) => {
        setFavoritesBreedStatus("get");
        setHighestSort("");
        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?orderBy=Id&orderByDesc=true&${hips}page=${page}&size=${size}`
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    }

    const getAllEventSearch = async (e, page, size) => {
        size = size ?? paginationAll.pageSize;
        e.preventDefault();
        setLoading(true);
        setHighestSort("");

        setBreedStatus("search");

        if (search.name == "") {
            getAllEventDetails(page, size);
        } else {
            const hips = buildHipNumbersQueryString();
            const url = `${allPath}?searchBy=sireOrDamName&searchTerm=${search.name}&${hips}page=${page}&pageSize=${size}`;
            await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
        }
    }

    const getFavoritesEventSearch = async (e, page, size) => {
        size = size ?? paginationAll.pageSize;
        e.preventDefault();
        setLoading(true);
        setHighestSort("");

        setFavoritesBreedStatus("search");

        if (search.name == "") {
            getFavoritesEventDetails(page, size);
        } else {
            const hips = buildHipNumbersQueryString();
            const url = `${favoritesPath}?searchBy=sireOrDamName&searchTerm=${search.name}&${hips}page=${page}&pageSize=${size}`;
            await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
        }
    }

    const getAllEventSearchPage = async (page, size) => {
        setLoading(false);

        setHighestSort("");
        setBreedStatus("search");

        if (search.name == "") {
            getAllEventDetails(page, size);;
        } else {
            const hips = buildHipNumbersQueryString();
            const url = `${allPath}?filters=HasSireName@=${search.name}&HasDamName@=${search.name}&${hips}page=${page}&pageSize=${size}`;
            await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
        }
    }

    const defaultAllSuccessCb = (data) => {
        setEventDetails(data);
        updateAllTabPagination(data);
    }
    const defaultFavoritesSuccessCb = (data) => {
        setFavoriteDetails(data);
        updateFavoritesTabPagination(data);
    }

    const defaultAllFailureCb = (data) => {
        setEventDetails([]);
    }
    const defaultFavoritesFailureCb = (data) => {
        setFavoriteDetails([]);
    }

    const getFavoritesEventSearchPage = async (page, size) => {
        setLoading(false);

        setHighestSort("");
        setFavoritesBreedStatus("search");

        if (search.name == "") {
            getFavoritesEventDetails(page, size);;
        } else {
            const hips = buildHipNumbersQueryString();
            const url = `${favoritesPath}?filters=HasSireName@=${search.name}&HasDamName@=${search.name}&${hips}page=${page}&pageSize=${size}`;
            await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
        }
    }

    const handleAllRemoveItem = async (del, page, size) => {
        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?searchBy=hipNumber&searchTerm=${del}&${hips}page=${page}&pageSize=${size}`;
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    }

    const handleFavoritesRemoveItem = async (del, page, size) => {
        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?filterBy=hipNumber&filterValue=${del}&${hips}page=${page}&pageSize=${size}`;
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    }

    const handleHipNumberFilter = async (e) => {
        e.preventDefault && e.preventDefault();
        const newVal = typeof (e) == "string" ? e : e.target[0]["value"];
        getAllEventFilterHipNumberPage(1, paginationAll.pageSize);
        getFavoritesEventFilterHipNumberPage(1, paginationFavorite.pageSize);
    }

    const getAllEventFilterHipNumberPage = async (page, size) => {
        setHighestSort("");
        setBreedStatus("filterHipNumber");

        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?${hips}page=${page}&pageSize=${size}`;
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    };

    const getFavoritesEventFilterHipNumberPage = async (hip, page, size) => {
        setHighestSort("");
        setFavoritesBreedStatus("filterHipNumber");

        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?filterBy=hipNumber&filterValue=${hip}&${hips}page=${page}&pageSize=${size}`;
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);

    }

    const getAllFiltersHighestLowest = async (e, page, size, info) => {
        e.preventDefault && e.preventDefault();
        setLoading(true);
        setSortPage(info);

        setHighestSort("Price");
        let newSort = highestStatus == true ? false : true;
        setHighestStatus(newSort);

        setBreedStatus("highestLowest");
        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?orderBy=price&orderByDesc=${info}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    };

    const getFavoritesFiltersHighestLowest = async (e, page, size, info) => {
        e.preventDefault();
        setLoading(true);
        setSortPage(info);

        setHighestSort("Price");
        let newSort = favoritesHighestStatus == true ? false : true;
        setFavoritesHighestStatus(newSort);

        setFavoritesBreedStatus("highestLowest");
        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?orderBy=price&orderByDesc=${info}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    };

    const getAllFiltersHighestLowestPage = async (page, size, info) => {
        setSortPage(info);
        setHighestSort("Price");

        let newSort = highestStatus == true ? false : true;
        setHighestStatus(newSort);

        setBreedStatus("highestLowest");
        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?sorts=${info ? "-HorsePrice" : "HorsePrice"}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    };


    const getFavoritesFiltersHighestLowestPage = async (page, size, info) => {
        setSortPage(info);
        setHighestSort("Price");

        let newSort = favoritesHighestStatus == true ? false : true;
        setFavoritesHighestStatus(newSort);

        setFavoritesBreedStatus("highestLowest");
        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?sorts=${info ? "-HorsePrice" : "HorsePrice"}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    };

    const buildHipNumbersQueryString = () => {
        if (!allEventStore.hipNumbers || allEventStore.hipNumbers?.length == 0) return "";

        const retval = `hipnumbers=${allEventStore.hipNumbers.map((h) => h.value).join('&hipNumbers=')}&`;
        return retval;
    }
    const getAllFiltersGsrSort = async (e, page, size, info) => {
        e.preventDefault();

        setHighestSort("");
        setSortPage(sort);
        setSort(sort)
        setGsrSort(info);

        setBreedStatus("filtersGsrSort");

        let newSort;
        if (gsrSort === info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setSort(newSort);
        const hips = buildHipNumbersQueryString();
        const url = (search.name == "") ?
            `${allPath}?orderBy=${info}&orderByDesc=${newSort}&${hips}page=${page}&pageSize=${size}` :
            `${allPath}?orderBy=${info}&orderByDesc=${newSort}&searchBy=sireOrDamName&searchTerm=${search.name}&${hips}&page=${page}&pageSize=${size}`;

        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    }

    const getFavoritesFiltersGsrSort = async (e, page, size, info) => {
        e.preventDefault();
        setLoading(true);

        setFavoritesHighestSort("");
        setFavoritesSortPage(favoritesSort);
        setFavoritesSort(favoritesSort)
        setFavoritesGsrSort(info);

        setFavoritesBreedStatus("filtersGsrSort");

        let newSort;
        if (gsrFavoritesSort === info) {
            newSort = sort == true ? false : true;
        } else {
            newSort = true;
        }

        setFavoritesSort(newSort);
        const hips = buildHipNumbersQueryString();
        const url = (search.name == "") ?
            `${favoritesPath}?orderBy=${info}&orderByDesc=${newSort}&${hips}page=${page}&pageSize=${size}` :
            `${favoritesPath}?orderBy=${info}&orderByDesc=${newSort}&searchBy=sireOrDamName&searchTerm=${search.name}&${hips}page=${page}&pageSize=${size}`;

        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    };

    const getAllFiltersGsrSortPage = async (page, size, new_sort, info) => {

        setLoading(true);
        setHighestSort("");
        setSortPage(new_sort);
        setGsrSort(info);
        setBreedStatus("filtersGsrSort");
        const hips = buildHipNumbersQueryString();
        const url = `${allPath}?orderBy=${info}&orderByDesc=${new_sort}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultAllSuccessCb, defaultAllFailureCb);
    };

    const getFavoritesFiltersGsrSortPage = async (page, size, new_sort, info) => {

        setLoading(true);
        setHighestSort("");
        setSortPage(new_sort);
        setGsrSort(info);
        setFavoritesBreedStatus("filtersGsrSort");
        const hips = buildHipNumbersQueryString();
        const url = `${favoritesPath}?orderBy=${info}&orderByDesc=${new_sort}&${hips}page=${page}&size=${size}`;
        await fetchData(url, defaultFavoritesSuccessCb, defaultFavoritesFailureCb);
    };

    useEffect(() => {

    }, []);

    const ClearAll = () => {
        getAllEventDetails(paginationAll.currentPage, paginationAll.pageSize);
        getFavoritesEventDetails(paginationFavorite.currentPage, paginationFavorite.pageSize);
        setSearch({ ...search, name: "" });
    };

    const ClearAllHip = () => {
        setHipNumbers([]);
    };


    const updateAllTabPagination = (data) => {
        const newAllVal = { ...paginationAll, pageSize: data.horses.pageSize, currentPage: data.horses.currentPage, totalItems: data.horses.totalItems, totalPages: data.horses.totalPages };
        setPaginationAll(newAllVal);
    }

    const updateFavoritesTabPagination = (data) => {
        const newAllVal = { ...paginationFavorite, pageSize: data.horses.pageSize, currentPage: data.horses.currentPage, totalItems: data.horses.totalItems, totalPages: data.horses.totalPages };
        setPaginationFavorite(newAllVal);
    }

    const updateAllTabPageAndSize = (pageInfo) => {
        const newAllVal = { ...paginationAll, ...pageInfo };
        setPaginationAll(newAllVal);
    }

    const updateFavoritesTabPageAndSize = (pageInfo) => {
        const newFavVal = { ...paginationFavorite, ...pageInfo };
        setPaginationFavorite(newFavVal);
    }

    const handleAllTabPagination = (page, size) => {
        console.log(page, size);

        updateAllTabPageAndSize({ currentPage: page, pageSize: size });
        size = size ?? paginationAll.pageSize;

        if (breedStatus == "get") {
            getAllEventDetails(page, size);
        } else if (breedStatus == "search") {
            getAllEventSearchPage(page, size);
        } else if (breedStatus == "filterHipNumber") {
            getAllEventFilterHipNumberPage(page, size);
        } else if (breedStatus == "highestLowest") {
            getAllFiltersHighestLowestPage(event, page, size, highestStatus);
        } else if (breedStatus == "filtersGsrSort") {
            getAllFiltersGsrSortPage(page, size, sortPage, gsrSort);
        }

    }

    const handleFavoritesTabPagination = (page, size) => {
        console.log(page, size);
        size = size ?? paginationFavorite.pageSize;

        updateFavoritesTabPageAndSize({ currentPage: page, pageSize: size });

        if (favoritesBreedStatus == "get") {
            getFavoritesEventDetails(page, size);
        } else if (favoritesBreedStatus == "search") {
            getFavoritesEventSearchPage(page, size);
        } else if (favoritesBreedStatus == "filterHipNumber") {
            getFavoritesEventFilterHipNumberPage(page, size);
        } else if (favoritesBreedStatus == "highestLowest") {
            getFavoritesFiltersHighestLowestPage(event, page, size, favoritesHighestStatus);
        } else if (favoritesBreedStatus == "filtersGsrSort") {
            getFavoritesFiltersGsrSortPage(page, size, favoritesSortPage, gsrFavoritesSort);
        }
    }


    const handleShare = async (id) => {
        const scb = (data) => {
            setShareID(data.token);
            setShowShare(true);
        }
        const url = `${API_URL}/Event/createeventlink/${id}`;
        await fetchData(url, scb);
    };

    const handleTabClick = (newTab) => {
        setTabActive(newTab);
    };

    const handleAllEventFavoriteClick = async (horseId, isFavorite) => {
        const url = `${API_URL}/event/${eventDetails.id}/toggle-favorite`;
        const body = { horseId: horseId, isFavorite };
        const scb = (data) => {
            // update the pages with the new data
            // NOTE: need to do a data callback because of the pagination
            // TODO: fix this so it doesn't need to call back and this can be done locally
            getAllEventSearchPage(paginationAll.currentPage, paginationAll.pageSize);
            getFavoritesEventSearchPage(paginationFavorite.currentPage, paginationFavorite.pageSize);
        };

        const fcb = () => {
            Swal.fire({
                icon: 'error',
                title: 'Favorite Updating Failed!',
            });
        };
        await putData(url, body, scb, fcb);
    }

    const putData = async (url, body, successCb, failureCb) => {
        setLoading(true);

        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);
        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(body),
            redirect: "follow",
        };

        try {
            const result = await fetch(url, requestOptions);
            const data = await result.json();
            console.log("result", data);
            if (result.status === 401) {
                console.log(result);
                return logout();
            }

            if (data.isSuccess == true) {
                successCb && successCb(data.successData);
            } else {
                failureCb && failureCb();
            }
        } catch (error) {
            console.log(error);
            failureCb();
        }
        setTimeout(() => {
            setLoading(false);
        }, 1200);

    }

    // put this last to do any initializations
    useSingleton({ eventId: id }, async (p) => {
        await flowResult(allEventStore.getHipNumberList());
        //await flowResult(allEventStore.getHorses());

        //await flowResult(favoritesEventStore.getHorses());

        getAllEventDetails(paginationAll.currentPage, paginationAll.pageSize);
        getFavoritesEventDetails(paginationFavorite.currentPage, paginationFavorite.pageSize);
        //loadHipNumbers(p.eventId);
    });

    return (
        <>
            <Layout>
                <Modal
                    show={show}
                    onClose={() => setShow(false)}
                    setShow={setShow}
                    horsesEdit={horsesEdit}
                    setHorsesEdit={setHorsesEdit}
                    pageSize={paginationAll.pageSize}
                    currentPage={paginationAll.currentPage}
                    //setCurrentPage={setAllCurrentPage}
                    handlePagination={handleAllTabPagination}
                />
                <NotesModal
                    noteShow={noteShow}
                    setNoteShow={setNoteShow}
                    onClose={() => setNoteShow(false)}
                    horsesEdit={horsesEdit}
                    setHorsesEdit={setHorsesEdit}
                    pageSize={paginationAll.pageSize}
                    currentPage={paginationAll.currentPage}
                    //setCurrentPage={setCurrentPage}
                    handlePagination={handleAllTabPagination}
                />
                <ShareModal
                    showShare={showShare}
                    id={shareID}
                    onClose={() => setShowShare(false)}
                />
                <div className="bg__area__content">
                    <div className="container">
                        <div className="py-2">
                            <div className="page_breadcum">
                                <ul>
                                    <li>
                                        {" "}
                                        <Link to="/events">
                                            <i className="fas fa-house"></i> Events /
                                        </Link>{" "}
                                    </li>
                                    <li className="active">
                                        {" "}
                                        <Link to={`/event/${id}`}>Event Details </Link>{" "}
                                    </li>
                                </ul>
                            </div>

                            <div className="table__header shadow-sm ">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h3>{eventDetails?.name}</h3>
                                    <button
                                        className="btn btn-default border text-primary"
                                        onClick={() => handleShare(id)}
                                    >
                                        <i className="fas fa-share-alt"></i> Share
                                    </button>
                                </div>

                                <div className="table__Tabs">
                                    <div
                                        className={`single-tab ${tabActive == 1 ? "active" : ""}`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        All Horses
                                    </div>
                                    <div
                                        className={`single-tab ${tabActive == 2 ? "active" : ""}`}
                                        onClick={() => handleTabClick(2)}
                                    >
                                        Favorite Horses
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between table__header__row">
                                    <div className="d-flex align-items-center gap-2 second-search__box">
                                        <form
                                            className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                                            onSubmit={(e) => {
                                                getAllEventSearch(e, 1, paginationAll.pageSize);
                                                getFavoritesEventSearch(e, 1, paginationFavorite.pageSize);
                                            }}
                                        >
                                            <div className="table_search_input input-group form-group position-relative">
                                                <input
                                                    type="text"
                                                    className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                                                    name="name"
                                                    value={search.name}
                                                    onChange={updateSearch}
                                                    placeholder="Search by sire or dam name"
                                                />
                                                <span
                                                    className="input-group-append position-absolute"
                                                    style={{
                                                        zIndex: "10",
                                                        marginTop: "-2px",
                                                        right: "-7px",
                                                    }}
                                                >
                                                    {search.name.length > 0 ? (
                                                        <div className="input-group-text border-0 bg-transparent ml-n5">
                                                            <span
                                                                className=""
                                                                style={{
                                                                    cursor: "pointer",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                                onClick={ClearAll}
                                                            >
                                                                <MdClear className="clear_logo" />
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </span>
                                            </div>
                                            <button
                                                className="btn btn-primary"
                                                style={{
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "22px",
                                                    marginRight: "5px",
                                                }}
                                            >
                                                Search
                                            </button>
                                        </form>

                                        <form
                                            className="d-flex align-items-center mb-2 gap-2 table__left_searchbtn"
                                            onSubmit={handleHipNumberFilter}
                                        >
                                            <div className="table_search_input input-group form-group position-relative">
                                                <Select
                                                    className={`table_search_input form-control rounded input__field flex-md-grow-3 ${searchErr ? "border-danger" : ""}`}
                                                    placeholder="Filter by hip numbers"
                                                    showSearch
                                                    labelInValue
                                                    mode="multiple"
                                                    value={allEventStore.hipNumbers}
                                                    loading={hipNumbersLoading}
                                                    onChange={(newValue) => {
                                                        allEventStore.hipNumbers = newValue;
                                                    }}
                                                    variant="borderless"
                                                    style={{
                                                        width: '100%',

                                                    }}
                                                    options={(allEventStore.hipNumbersLookup || [])}
                                                />
                                            </div>
                                            <button className="btn btn-primary">
                                                {/* <i className="fas fa-filter" style={{ fontSize: 12 }}></i>  */}
                                                <AiFillFilter
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    Filter
                                                </span>
                                            </button>
                                        </form>
                                    </div>
                                    <div className="d-flex">
                                        <div
                                            className="dropdown"
                                            style={{ width: "160px!important" }}
                                        >
                                            <button
                                                className="btn btn-default mx-2 border d-flex align-items-center justify-content-center text-center"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                style={{
                                                    width: "170px",
                                                    height: "39px",
                                                }}
                                            >
                                                <AiFillFilter
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                        marginRight: "5px",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "22px",
                                                    }}
                                                >
                                                    Sort by Price
                                                </span>
                                            </button>
                                            <ul
                                                className="dropdown-menu border-start border-end border-bottom border-top-0"
                                                style={{ width: "170px !important" }}
                                            >
                                                <li
                                                    onClick={(e) => {
                                                        getAllFiltersHighestLowest(e, 1, 10, true);
                                                        getFavoritesFiltersHighestLowest(e, 1, 10, true);
                                                    }}
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight: "22px",
                                                        }}
                                                    >
                                                        Highest to Lowest
                                                    </a>
                                                </li>
                                                <li
                                                    onClick={(e) => {
                                                        getAllFiltersHighestLowest(e, 1, 10, false);
                                                        getFavoritesFiltersHighestLowest(e, 1, 10, false);
                                                    }}
                                                >
                                                    <a
                                                        className="dropdown-item"
                                                        href="#"
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight: "22px",
                                                        }}
                                                    >
                                                        Lowest to Highest
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {tabActive == 1 ? (
                                    <div className="table-responsive table__fix_head mt-1">
                                        <table className="table mt-1">
                                            <tr className="table__head__row">
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "48px" }}
                                                ></th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "60px", paddingLeft: "0px" }}
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "147.57px", paddingLeft: "0px" }}
                                                >
                                                    Sire Name
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "147.57px", paddingLeft: "0px" }}
                                                >
                                                    Dam Name
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "147.57px", paddingLeft: "0px" }}
                                                >
                                                    New Horse
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "60px", paddingLeft: "0px" }}
                                                >
                                                    Sex
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) => {
                                                        getAllFiltersGsrSort(e, 1, 10, "hipNumber")

                                                    }
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Hip
                                                        {gsrSort == "hipNumber" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrSort == "hipNumber" ? true : false
                                                                }
                                                                sort={sort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={sort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) => {
                                                        getAllFiltersGsrSort(e, 1, 10, "gsrDirt")

                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Dirt
                                                        {gsrSort == "gsrDirt" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrSort == "gsrDirt" ? true : false
                                                                }
                                                                sort={sort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={sort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) => {
                                                        getAllFiltersGsrSort(e, 1, 10, "gsrTurf")

                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Turf
                                                        {gsrSort == "gsrTurf" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrSort == "gsrTurf" ? true : false
                                                                }
                                                                sort={sort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={sort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) => {
                                                        getAllFiltersGsrSort(e, 1, 10, "gsrSynth")

                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Synth
                                                        {gsrSort == "gsrSynth" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrSort == "gsrSynth" ? true : false
                                                                }
                                                                sort={sort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={sort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) => {
                                                        getAllFiltersGsrSort(e, 1, 10, "gsrWet")

                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Wet
                                                        {gsrSort == "gsrWet" ? (
                                                            <SortIcon
                                                                select={gsrSort == "gsrWet" ? true : false}
                                                                sort={sort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={sort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{
                                                        width: "100px !important",
                                                        paddingLeft: "0px",
                                                    }}
                                                    onClick={(e) => {
                                                        highestStatus
                                                            ? getAllFiltersHighestLowest(e, 1, 10, false)
                                                            : getAllFiltersHighestLowest(e, 1, 10, true);
                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Price
                                                        {highestSort == "Price" ? (
                                                            <SortIcon
                                                                select={highestSort == "Price" ? true : false}
                                                                sort={highestStatus}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={highestStatus} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "70px", paddingLeft: "0px" }}
                                                >
                                                    Notes
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "213px", paddingLeft: "0px" }}
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                            {loading ? (
                                                <tr className="table__row table__row_content table-loading">
                                                    <td className="text-center" colSpan={14}>
                                                        <TableLoading />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {!eventDetails && !eventDetails.horses && !eventDetails.horses.result ? (
                                                        <tr className="table__row table__row_content">
                                                            <td className="text-center" colSpan={14}>
                                                                <div
                                                                    className="w-100 d-flex justify-content-center"
                                                                    style={{ height: "55vh" }}
                                                                >
                                                                    <img
                                                                        src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                                                        alt=""
                                                                        style={{ width: "150px" }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {eventDetails.horses.result.map((item, index) => (
                                                                <ViewEventRow
                                                                    key={index}
                                                                    item={item}
                                                                    setShow={setShow}
                                                                    noteShow={noteShow}
                                                                    setNoteShow={setNoteShow}
                                                                    horses={horses}
                                                                    setHorses={setHorses}
                                                                    horsesEdit={horsesEdit}
                                                                    setHorsesEdit={setHorsesEdit}
                                                                    getEventDetails={getAllEventDetails}
                                                                    handlePagination={handleAllTabPagination}
                                                                    pageSize={paginationAll.pageSize}
                                                                    currentPage={paginationAll.currentPage}
                                                                    breedStatus={breedStatus}
                                                                    setBreedStatus={setBreedStatus}
                                                                    getEventFavorite={getFavoritesEventDetails}
                                                                    getEventSearchPage={getAllEventSearchPage}
                                                                    onEventFavoriteClick={handleAllEventFavoriteClick}
                                                                />
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </table>
                                    </div>
                                ) : (
                                    <div className="table-responsive table__fix_head mt-1">
                                        <table className="table mt-1">
                                            <tr className="table__head__row">
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "48px" }}
                                                ></th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "60px", paddingLeft: "0px" }}
                                                >
                                                    ID
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "178px", paddingLeft: "0px" }}
                                                >
                                                    Sire Name
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "178px", paddingLeft: "0px" }}
                                                >
                                                    Dam Name
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "161px", paddingLeft: "0px" }}
                                                >
                                                    New Horse
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "60px", paddingLeft: "0px" }}
                                                >
                                                    Sex
                                                </th>
                                                {/* <th
                          className="table__tdp"
                          style={{ width: "80px", paddingLeft: "0px" }}
                        >
                          Hip
                        </th> */}
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) =>
                                                        getFavoritesFiltersGsrSort(e, 1, 10, "hipNumber")
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Hip
                                                        {gsrFavoritesSort == "hipNumber" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrFavoritesSort == "hipNumber" ? true : false
                                                                }
                                                                sort={favoritesSort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesSort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) =>
                                                        getFavoritesFiltersGsrSort(e, 1, 10, "gsrDirt")
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Dirt
                                                        {gsrFavoritesSort == "gsrDirt" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrFavoritesSort == "gsrDirt" ? false : true
                                                                }
                                                                sort={favoritesSort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesSort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) =>
                                                        getFavoritesFiltersGsrSort(e, 1, 10, "gsrTurf")
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Turf
                                                        {gsrFavoritesSort == "gsrTurf" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrFavoritesSort == "gsrTurf" ? false : true
                                                                }
                                                                sort={favoritesSort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesSort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) =>
                                                        getFavoritesFiltersGsrSort(e, 1, 10, "gsrSynth")
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Synth
                                                        {gsrFavoritesSort == "gsrSynth" ? (
                                                            <SortIcon
                                                                select={
                                                                    gsrFavoritesSort == "gsrSynth" ? false : true
                                                                }
                                                                sort={favoritesSort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesSort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "90px !important", paddingLeft: "0px" }}
                                                    onClick={(e) =>
                                                        getFavoritesFiltersGsrSort(e, 1, 10, "gsrWet")
                                                    }
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Wet
                                                        {gsrFavoritesSort == "gsrWet" ? (
                                                            <SortIcon
                                                                select={gsrFavoritesSort == "gsrWet" ? false : true}
                                                                sort={favoritesSort}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesSort} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{
                                                        width: "100px !important",
                                                        paddingLeft: "0px",
                                                    }}
                                                    onClick={(e) => {

                                                        favoritesHighestStatus
                                                            ? getFavoritesFiltersHighestLowest(e, 1, 10, false)
                                                            : getFavoritesFiltersHighestLowest(e, 1, 10, true);
                                                    }}
                                                >
                                                    <span
                                                        className="d-flex align-items-center justify-content-start"
                                                        style={{
                                                            width: "100%",
                                                            height: "45px",
                                                            backgroundColor: "#FFF0",
                                                            marginLeft: "-7px",
                                                        }}
                                                    >
                                                        Price
                                                        {highestSort == "Price" ? (
                                                            <SortIcon
                                                                select={highestSort == "Price" ? true : false}
                                                                sort={favoritesHighestStatus}
                                                            />
                                                        ) : (
                                                            <SortIcon select={null} sort={favoritesHighestStatus} />
                                                        )}
                                                    </span>
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "70px", paddingLeft: "0px" }}
                                                >
                                                    Notes
                                                </th>
                                                <th
                                                    className="table__tdp"
                                                    style={{ width: "213px", paddingLeft: "0px" }}
                                                >
                                                    Action
                                                </th>
                                            </tr>
                                            {loading ? (
                                                <tr className="table__row table__row_content table-loading">
                                                    <td className="text-center" colSpan={14}>
                                                        <TableLoading />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {!favoriteDetails && !favoriteDetails.horses && !favoriteDetails.horses.result ? (
                                                        <tr className="table__row table__row_content">
                                                            <td className="text-center" colSpan={14}>
                                                                <div
                                                                    className="w-100 d-flex justify-content-center"
                                                                    style={{ height: "55vh" }}
                                                                >
                                                                    <img
                                                                        src={`${baseUrl}/assets/img/icon/no_data_found.svg`}
                                                                        alt=""
                                                                        style={{ width: "150px" }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {favoriteDetails.horses.result.map((item, index) => (
                                                                <ViewEventFavoriteRow
                                                                    key={index}
                                                                    item={item}
                                                                    setShow={setShow}
                                                                    noteShow={noteShow}
                                                                    setNoteShow={setNoteShow}
                                                                    horses={favoritesHorses}
                                                                    pageSize={paginationFavorite.pageSize}
                                                                    currentPage={paginationFavorite.currentPage}
                                                                    setHorses={setFavoritesHorses}
                                                                    horsesEdit={favoritesHorsesEdit}
                                                                    setHorsesEdit={setHorsesEdit}
                                                                    getEventDetails={getFavoritesEventDetails}
                                                                    handlePagination={handleFavoritesTabPagination}
                                                                    breedStatus={favoritesBreedStatus}
                                                                    setBreedStatus={setFavoritesBreedStatus}
                                                                    getEventFavorite={getFavoritesEventDetails}
                                                                    getEventSearchPage={getFavoritesEventSearchPage}
                                                                    onEventFavoriteClick={handleAllEventFavoriteClick}
                                                                />
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </table>
                                    </div>
                                )}
                                {/* Tab - 1 */}
                                {tabActive == 1 ? (
                                    <>

                                        {!eventDetails ? null : (
                                            <div className="table__pagination mt-3">
                                                <Pagination id="allTab"
                                                    total={paginationAll.totalItems}
                                                    onChange={(page, pageSize) => handleAllTabPagination(page, pageSize)}
                                                    defaultPageSize={paginationAll.pageSize}
                                                    defaultCurrent={paginationAll.currentPage}
                                                />
                                            </div>

                                        )}</>) : (
                                    <>


                                        {!favoriteDetails ? null : (
                                            <div className="table__pagination mt-3">
                                                &nbsp;
                                                <Pagination
                                                    total={paginationFavorite.totalItems}
                                                    onChange={(page, pageSize) => handleFavoritesTabPagination(page, pageSize)}
                                                    defaultPageSize={paginationFavorite.pageSize}
                                                    defaultCurrent={paginationFavorite.currentPage}
                                                />&nbsp;
                                            </div>

                                        )}</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
});

export default EventDetails2;
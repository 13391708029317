/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Layout from './Layouts/Layout'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import { useLoginMutation } from '../features/api/authApi';
import { useSelector, useDispatch } from 'react-redux';
import { setToken } from '../features/slices/authslice';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { API_URL } from '../config/config'


// interface ForgotProps {
//     newPassword: string;
//     confirmPassword: string;
// }

const ForgotPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [passShow, setPassShop] = useState(false);
    const [passConShow, setPassConShop] = useState(false);
    const [step, setStep] = useState(1);

    const getCharacterValidationError = (str) => {
        return `Your password must have at least 1 ${str} character`;
    };

    const ForgotSchema = yup.object().shape({
        password: yup.string()
            .required("Please enter a password")
            .min(8, "Password must have at least 8 characters")
            .matches(/[0-9]/, getCharacterValidationError("digit"))
            .matches(/[a-z]/, getCharacterValidationError("lowercase"))
            .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
        confirmPassword: yup.string()
            .required("Please re-type your password")
            .oneOf([yup.ref("password")], "Passwords does not match"),
    });


    const renderError = (message) => <p className="text-danger">{message}</p>;

    const [user, setUser] = useState({
        email: '',
    })

    const [newPass, setNewPass] = useState({
        newPassword: '',
        confirmPassword: '',
        confirmationCode: '',
        email: '',
    })


    const nextRoute = (e) => {
        e.preventDefault()
        navigate('/login')
    }


    const ShowHandel = () => {
        if (passShow == false) {
            setPassShop(true)
        } else {
            setPassShop(false)
        }
    }
    const ShowHandelCon = () => {
        if (passConShow == false) {
            setPassConShop(true)
        } else {
            setPassConShop(false)
        }
    }


    const updateForgotPassword = (e) => {
        setNewPass({ ...newPass, [e.target.name]: e.target.value });
        if (e.target.value.length == 6) {
            setStep(3);
        }
    };


    const SubmitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                email: user.email,
            }),
            redirect: 'follow'
        };

        const response = await fetch(`${API_URL}/User/v2/forgot-password-with-2fa`, requestOptions);
        const result = await response.json();;

        //console.log('response', response.status);
        //console.log('result', result);

        if (result.isSuccess == true) {

            if (result.successData.isSuccess == true) {
                Swal.fire({
                    icon: 'success',
                    title: "Check Your Email For The OTP",
                })
                setStep(2);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: result.successData.message,
                })
            }
        }

        setIsLoading(false);
    }

    const ForgotPasswordSubmitHandler = async (password, confirmPassword) => {
        // e.preventDefault();
        setIsLoading(true);

        if (newPass.newPassword == newPass.confirmPassword) {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    newPassword: password,
                    confirmPassword: confirmPassword,
                    confirmationCode: newPass.confirmationCode,
                    email: newPass.email,
                }),
                redirect: 'follow'
            };

            const response = await fetch(`${API_URL}/User/v2/reset-password-with-2fa`, requestOptions);
            const result = await response.json();;

            //console.log('response', response.status);
            //console.log('result', result);

            if (result.isSuccess == true) {

                if (result.successData.isSuccess == true) {
                    Swal.fire({
                        icon: 'success',
                        title: "Your Password Has Been Reset Successfully",
                    })
                    setStep(4);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "Failed To Reset Your Password.",
                    })
                }
            }

            setIsLoading(false);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Sorry, the passwords provided do not match. Please retry',
            })
        }
    }


    return (
        <Layout>
            <div className='bg__area'>
                <div className='form__box__area login_box'>
                    {
                        step == 1 ? <>
                            <h3
                                className='form__heading_center'
                                style={{
                                    fontSize: '28px',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    color: 'rgba(66, 66, 66, 1) !important',
                                }}
                            >Forgot Password?</h3>

                            <p className='text-center' style={{ fontSize: '14px', fontWeight: '400', lineHeight: '17px', color: 'rgba(140, 140, 140, 1)', marginTop: '25px' }}>
                                Enter Your Registered Email To Reset Your Password
                            </p>

                            <div className='form__box mt-4'>
                                <form onSubmit={SubmitHandler}>
                                    <div className='form-group' style={{ marginTop: '35px' }}>
                                        <input type="email" value={user.email} onChange={(e) => {
                                            setUser({ ...user, email: e.target.value });
                                            setNewPass({ ...newPass, email: e.target.value });
                                        }} className='form-control rounded input__field' placeholder='Email' />
                                    </div>

                                    <div className='form-group mt-4'>
                                        <button type='submit' className='btn btn-default rounded login__btn'>{isLoading ? 'Processing...' : 'Submit'}</button>
                                    </div>
                                </form>
                            </div>
                        </> : null
                    }
                    {
                        step == 2 ? <>
                            <h3
                                className='form__heading_center'
                                style={{
                                    fontSize: '28px',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    color: 'rgba(66, 66, 66, 1) !important',
                                }}
                            >Verification</h3>

                            <p className='text-center' style={{ fontSize: '14px', fontWeight: '400', lineHeight: '17px', color: 'rgba(140, 140, 140, 1)', marginTop: '25px' }}>
                                Enter Verification Code
                            </p>

                            <div className='form__box mt-4'>
                                <form onSubmit={SubmitHandler}>
                                    <div className='form-group' style={{ marginTop: '35px' }}>
                                        <input
                                            type="text"
                                            name="confirmationCode"
                                            value={newPass.confirmationCode}
                                            onChange={updateForgotPassword}
                                            className='form-control rounded input__field text-center'
                                            placeholder='xxxxxx' />
                                    </div>

                                    <div className='form-group text-start mt-3'>
                                        If you didn't receive code! <span onClick={SubmitHandler} className='forgot__password text-primary' style={{ textDecoration: 'none', cursor: 'pointer' }}>Resend</span>
                                    </div>
                                </form>
                            </div>
                        </> : null
                    }
                    {
                        step == 3 ? <>
                            <h3
                                className='form__heading_center'
                                style={{
                                    fontSize: '28px',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    color: 'rgba(66, 66, 66, 1) !important',
                                }}
                            >Enter New Password</h3>

                            {/* <p style={{ fontSize: '14px', fontWeight: '400', lineHeight: '17px', color: 'rgba(140, 140, 140, 1)', marginTop: '25px' }}>
                                Please keep only "Enter New Password" here
                            </p> */}

                            <div className='form__box mt-4'>
                                <Formik
                                    initialValues={{ password: "", confirmPassword: "" }}
                                    onSubmit={(values) => {
                                        // console.log("values ::", values);
                                        setNewPass({
                                            ...newPass, newPassword: values.password,
                                            confirmPassword: values.confirmPassword,
                                        });

                                        ForgotPasswordSubmitHandler(values.password, values.confirmPassword);
                                    }}
                                    validationSchema={ForgotSchema}
                                >
                                    {/*  validationSchema={ForgotSchema} onSubmit={ForgotPasswordSubmitHandler} */}
                                    {
                                        ({ errors }) => {
                                            return (
                                                <Form>
                                                    <div className=' mb-2 '>
                                                        <div className="input-group form-group position-relative">
                                                            <Field
                                                                type={passShow ? `text` : `password`}
                                                                name="password"
                                                                className="form-control rounded input__field border-end-0'" placeholder='New Password' />
                                                            <span
                                                                className="input-group-append position-absolute"
                                                                style={{
                                                                    zIndex: '10',
                                                                    marginTop: '6px',
                                                                    right: '3px'
                                                                }}>
                                                                <div className="input-group-text border-0 bg-transparent ml-n5">
                                                                    {
                                                                        passShow ?
                                                                            <AiOutlineEye onClick={ShowHandel} style={{ cursor: 'pointer', backgroundColor: '#FFF', fontSize: "14px", color: 'rgba(140, 140, 140, 1)' }} /> :
                                                                            <AiOutlineEyeInvisible onClick={ShowHandel} style={{ cursor: 'pointer', backgroundColor: '#FFF', fontSize: "16px", color: 'rgba(140, 140, 140, 1)' }} />
                                                                    }
                                                                </div>
                                                            </span>
                                                        </div>
                                                        {!!errors.password && (
                                                            <p className="text-danger">{errors.password}</p>
                                                        )}
                                                    </div>

                                                    <div className=' mb-2 '>
                                                        <div className="input-group form-group  mb-2 position-relative">
                                                            <Field
                                                                type={passConShow ? `text` : `password`}
                                                                name="confirmPassword"
                                                                className="form-control rounded input__field border-end-0'" placeholder='Confirm Password' />
                                                            <span
                                                                className="input-group-append position-absolute"
                                                                style={{
                                                                    zIndex: '10',
                                                                    marginTop: '6px',
                                                                    right: '3px'
                                                                }}>
                                                                <div className="input-group-text border-0 bg-transparent ml-n5">
                                                                    {
                                                                        passConShow ?
                                                                            <AiOutlineEye onClick={ShowHandelCon} style={{ cursor: 'pointer', backgroundColor: '#FFF', fontSize: "14px", color: 'rgba(140, 140, 140, 1)' }} /> :
                                                                            <AiOutlineEyeInvisible onClick={ShowHandelCon} style={{ cursor: 'pointer', backgroundColor: '#FFF', fontSize: "16px", color: 'rgba(140, 140, 140, 1)' }} />
                                                                    }
                                                                </div>
                                                            </span>
                                                        </div>
                                                        {!!errors.confirmPassword && (
                                                            <p className="text-danger">{errors.confirmPassword}</p>
                                                        )}
                                                    </div>

                                                    <div className='form-group mt-4'>
                                                        <button className='btn btn-default rounded login__btn'>
                                                            {isLoading ? 'Processing...' : 'Submit'}
                                                        </button>
                                                    </div>
                                                </Form>
                                            );
                                        }
                                    }
                                </Formik>
                            </div>
                        </> : null
                    }
                    {
                        step == 4 ? <>
                            <h3
                                className='form__heading_center mb-8'
                                style={{
                                    fontSize: '28px',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    color: 'rgba(66, 66, 66, 1) !important',
                                }}
                            ></h3>

                            {/* <p style={{ fontSize: '14px', fontWeight: '400', lineHeight: '17px', color: 'rgba(140, 140, 140, 1)', marginTop: '25px' }}>
                                Please keep only "Enter New Password" here
                            </p> */}

                            <div className='form__box'  style={{ marginTop: '30% !important'}}>
                                <div className="form-group mt-5  mb-2">
                                    <p style={{ fontSize: '14px', fontWeight: '400', lineHeight: '17px', color: 'rgba(140, 140, 140, 1)', marginTop: '25px' }}>
                                        Your Password Has Been Successfully Updated
                                    </p>
                                </div>
                                <div className='form-group'>
                                    <a href='/login' type='submit' className='btn btn-default rounded login__btn'>Login</a>
                                </div>
                            </div>
                        </> : null
                    }
                </div>
            </div>
        </Layout >
    )
}

export default ForgotPassword
export const isCpuSelectedStyle = (isCpuSelected) => {
    if (isCpuSelected === true) {
        return {
            color: "#FF0000",
        };
    } else {
        return {
            color: "#000",
        };
    }
};

export const truncateCell = () => {
    return {
        "whiteSpace": "nowrap",
        "textOverflow": "ellipsis",
        "display": "block",
        "width": "110px",
        "overflow": "hidden"
    };
}

export const formatNumber = (number) => {
    number = number || 0;
    // Use the toLocaleString method to add suffixes to the number
    return number.toLocaleString('en-US', {
        // add suffixes for thousands, millions, and billions
        // the maximum number of decimal places to use
        maximumFractionDigits: 2,
        // specify the abbreviations to use for the suffixes
        notation: 'compact',
        compactDisplay: 'short'
    });
}

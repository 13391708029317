import { JsonRequestBuilder } from '../../common/http-builder';
import { computed, flow, makeAutoObservable } from "mobx";
import { API_URL } from "../../config/config";

class SortOption {
    field = null;
    order = null;
}

class FilterOption {
    field = null;
    value = null;
    operator = null;
}

class PaginationOptions {
    currentPage = null;
    pageSize = null;
    totalItems = null;
    totalPages = null;
}

class FetchOptions {
    paginationOptions = {};
    sortOptions = [];
    filterOptions = [];
    hipNumbers = [];
}

export class EventStore {

    eventId = 0;
    sort = new SortOption();
    filters = [];
    pagination = new PaginationOptions();
    hipNumbers = [];
    path = "";
    method = "GET";
    useAuth = true;
    pendingRequests = 0;
    hipNumbersLookup = [];
    horses = [];
    constructor() {
        this.sort = { field: "id", order: "DESC" };
        this.pagination = { currentPage: 1, pageSize: 10, ...this.pagination };

        makeAutoObservable(this, {
            headers: computed,
            body: computed,
            url: computed,
            getHipNumberList: flow,
            getHorses: flow,
        });
    }

    // const favoritesPath = `${API_URL}/Event/byid/${id}/favorites`;
    // const allPath = `${API_URL}/Event/byid/${id}`;

    get url() {
        return this.path;
    }

    get body() {
        return {
            pagination: this.pagination,
            sort: this.sort,
            filters: this.filters,
            hipNumbers: this.hipNumbers,
        };
    }

    get headers() {
        const auth = JSON.parse(localStorage.getItem("auth"));
        const retval = new Headers();
        retval.append("Content-Type", "application/json");
        if (this.useAuth) { retval.append("Authorization", `Bearer ${auth.token}`); }
        return retval;
    }

    *getHipNumberList() {
        try {
            const url = `${API_URL}/event/${this.eventId}/hip-numbers`;
            const builder = new JsonRequestBuilder();
            const req = builder
                .init()
                .useMethod("GET")
                .withAuth()
                .withUrl(url)
                .get();

            const result = yield fetch(req.url, req.requestInit)
                .then((response) => response.json())
                .then((body) => {
                    console.debug(body);
                    return body.successData.map((h) => ({
                        label: h,
                        value: h,
                    }));
                });
            console.debug(result);

            this.hipNumbersLookup = result;

        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    *getHorses() {
        this.pendingRequests++;
        try {
            const builder = new JsonRequestBuilder();
            const req = builder
                .init()
                .useMethod("POST")
                .withAuth()
                .withBody(this.body)
                .withUrl(this.path)
                .get();

            const data = yield fetch(req.url, req.requestInit).then((response) => {
                if (response.status === 401) {
                    throw new Error(401);
                }
                response.json()
            });

            return data;
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.pendingRequests--;
        }
    }
}

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Nav from "./components/Nav";
import SalseAssistant from "./components/SalseAssistant";
import Events from "./components/Events";
import BreedHorses from "./components/pages/BreedHorses";
import BreedHistory from "./components/BreedHistory";
import AddToEvent from "./components/AddToEvent";
import FavouriteHorses from "./components/FavouriteHorses";
import EventDetails from "./components/EventDetails";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { Redirect } from "./components/Redirect";
import { useDispatch } from "react-redux";
import { processToken, logout } from "./features/slices/authslice";
import PrivateRoute from "./utils/PrivateRoute";
import 'react-toastify/dist/ReactToastify.css';
import EventBreed from "./components/EventBreed";
import EventShare from "./components/EventShare";
import AuthVerify from "./common/auth-verify";
import EventDetails2 from "./components/EventDetails2";
import { EventStore } from "./features/events/events-store";

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(processToken())
  }, [])

  const logOut = () => {
    dispatch(logout());
  }

  const allEventStore = new EventStore();
  const favoritesEventStore = new EventStore();
  return (
    <div>
      <div>
        <Routes>
          {/* Public Routees  */}
          <Route path="/" element={<Redirect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/share/:id" element={<EventShare />} />


          {/* Protected Routes  */}
          <Route element={<PrivateRoute />}>
            <Route path="/breed-horses" element={<SalseAssistant />} />
            <Route path="/events" element={<Events />} />
            <Route path="/breed-history" element={<BreedHistory />} />
            <Route path="/bred-horses" element={<BreedHorses />} />
            <Route path="/add-to-event" element={<AddToEvent />} />
            <Route path="/event/:id" element={<EventDetails />} />
            <Route path="/event2/:id" element={<EventDetails2 allEventStore={allEventStore} favoritesEventStore={favoritesEventStore} />} />
            <Route path="/favourites" element={<FavouriteHorses />} />
            <Route path="/event-breed" element={<EventBreed />} />
            <Route path="/share/:id" element={<EventShare />} />
          </Route>
        </Routes>
      </div>
      <AuthVerify logOut={logOut} />
    </div>
  );
}

export default App;

import React from 'react';

import { JsonRequestBuilder } from '../common/http-builder'
import { API_URL } from '../config/config'
import DebounceSelect from './elements/DebounceSelect';
import moment from "moment";
import { SmileOutlined } from '@ant-design/icons';

export const BreedHorseSearchResultRender = ({ item, onClick }) => {
    return (
        <div onClick={onClick} style={{ padding: "1px", fontSize: 'medium', display: 'block', borderLeft: '1px black solid', marginBottom: '5px' }}>
            <div>{item.data.horseName}</div> <span style={{ fontSize: 'small' }}>{moment(item.data.foalingDate).format(
                "MM/DD/YYYY"
            )}</span>
        </div>)
}

async function fetchHorseList(horseName, cfg) {
    console.log('fetching horse', horseName);
    const builder = new JsonRequestBuilder();
    const url = `${API_URL}/BreedHorses/SearchByHorseName?horseName=${horseName}&isSire=${cfg.isSire}`
    const req = builder
        .init()
        .withAuth()
        .useMethod("GET")
        .withUrl(url)
        .get();

    return fetch(req.url, req.requestInit)
        .then((response) => response.json())
        .then((body) => {
            console.debug(body);
            const retval = body.successData.map((h) => ({
                label: h,
                value: h.horseName,
                ...h
            }));

            return retval;
        });
}

const BreedHorsesEmpty = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <SmileOutlined style={{ fontSize: 20 }} />
            <p style={{ fontSize: 'medium' }}>
                No Horses found
            </p>
            <p style={{ fontSize: 'small' }}>
                (type 3 characters to search)
            </p>
        </div>);
}


const BreedHorseTypeAhead = (props) => {
    return (
        <DebounceSelect
            fetchOptions={fetchHorseList}
            notFoundRender={<BreedHorsesEmpty />}
            {...props}
        />
    );
};

export default BreedHorseTypeAhead;
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from 'react';
import moment from "moment";
import Swal from 'sweetalert2';

import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { ToastContainer, toast } from "react-toastify";
import { baseUrl, API_URL } from '../../config/config';
import ListGroup from 'react-bootstrap/ListGroup';
import { logout } from '../../features/slices/authslice';
import BreedHorseTypeAhead, { BreedHorseSearchResultRender } from '../BreedHorseTypeAhead';
import { damSearchConfig } from '../BreedHorseTypeAhead';
import { ConfigProvider } from 'antd';
import { observer } from 'mobx-react';
import { Select, Spin, Empty } from 'antd';
import { flow, makeAutoObservable } from 'mobx';
import { JsonRequestBuilder } from '../../common/http-builder';
import {formatNumber} from "../../common/style-functions";

class HorseStore {
    name;
    id;
    sireName;
    damName;
    sex;
    hipNumber;
    consignor;
    price;
    equibaseRegistrationNumber;
    sireEquibaseRegistrationNumber;
    damEquibaseRegistrationNumber;

    state = "waiting";

    constructor(horse) {
        this.name = horse.name;
        this.id = horse.id;
        this.sireName = horse.sireName;
        this.damName = horse.damName;
        this.name = horse.name;
        this.sex = horse.sex;
        this.hipNumber = horse.hipNumber;
        this.consignor = horse.consignor;
        this.price = horse.price;
        this.equibaseRegistrationNumber = horse.equibaseRegistrationNumber;
        this.sireEquibaseRegistrationNumber = horse.sireEquibaseRegistrationNumber;
        this.damEquibaseRegistrationNumber = horse.damEquibaseRegistrationNumber;

        this.sireSearchValue = null;
        this.sireOptions = [];

        this.damSearchValue = null;
        this.damOptions = [];

        makeAutoObservable(this, {
            fetchSires: flow,
            fetchDams: flow
        });
    }

    *fetchSires(sireName) {
        this.sireOptions = [];
        this.state = "pending";

        try {
            const builder = new JsonRequestBuilder();
            const url = `${API_URL}/BreedHorses/SearchByHorseName?horseName=${sireName}&isSire=true`
            const req = builder
                .init()
                .withAuth()
                .useMethod("GET")
                .withUrl(url)
                .get();

            const options = yield fetch(req.url, req.requestInit)
                .then((response) => response.json())
                .then((body) => {
                    console.debug(body);
                    const retval = body.successData.map((h) => ({
                        label: h,
                        value: h.horseName,
                        ...h
                    }));

                    return retval;
                });
            this.state = "waiting";
            this.sireOptions = options;
            return options;

        } catch (error) {
            console.error(error);
            this.state = "error";
        }
    }

    *fetchDams(damName) {
        this.damOptions = [];
        this.state = "pending";

        try {
            const builder = new JsonRequestBuilder();
            const url = `${API_URL}/BreedHorses/SearchByHorseName?horseName=${damName}&isSire=false`
            const req = builder
                .init()
                .withAuth()
                .useMethod("GET")
                .withUrl(url)
                .get();

            const options = yield fetch(req.url, req.requestInit)
                .then((response) => response.json())
                .then((body) => {
                    console.debug(body);
                    const retval = body.successData.map((h) => ({
                        label: h,
                        value: h.horseName,
                        ...h
                    }));

                    return retval;
                });
            this.state = "done";
            this.damOptions = options;
            return options;

        } catch (error) {
            console.error(error);
            this.state = "error";
        }

    }
};

const HorseNotFound = observer(({ fetching, notFoundRender, ...props }) => {
    if (fetching === "waiting") {
        return (<div style={{ marginTop: '25px', paddingBottom: '25px' }}>
            <Spin size="medium" style={{ paddingLeft: '5px' }} tip="Retrieving horses..." >
                <div className="content" /></Spin></div>);
    } else if (fetching === "pending") {
        return notFoundRender();
    }
});

const HorseSelect = observer(({ fetcher, options, fetching, ...props }) => {
    <Select
        showSearch
        filterOption={false}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        onSearch={fetcher}
        notFoundContent={<HorseNotFound fetching={fetching} notFoundRender={props.notFoundRender} />}
        {...props}
        options={options}
    />
});

const Modal = ({ show, onClose, horsesEdit, setHorsesEdit }) => {
    if (!show) return;

    const [sireSearchConfig, setSireSearchConfig] = useState({ horseName: '', isSire: true });
    const [damSearchConfig, setDamSearchConfig] = useState({ horseName: '', isSire: false });

    const horseStore = new HorseStore(horsesEdit);

    //console.log('horsesEdit', horsesEdit);

    const lenRef = useRef(null);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [sireInput, setSireInput] = useState({})
    const [damInput, setDamInput] = useState({})

    const [sireOptions, setSireOptions] = useState([]);
    const [damOptions, setDamOptions] = useState([]);

    const [noteShow, setNoteShow] = useState(false);
    const [note, setNote] = useState({
        note: "",
        horseId: horsesEdit?.id == '' ? "" : horsesEdit?.id,
    });
    const [noteErroer, setNoteError] = useState(false);

    const [errors, setErrors] = useState({
        hipNumber: "",
        name: "",
        price: "",
        consignor: "",
    });

    const [editHorses, setEditHorses] = useState({
        id: horsesEdit?.id,
        sireName: horsesEdit?.sireName,
        damName: horsesEdit?.damName,
        name: horsesEdit?.horseName ?? horsesEdit?.name,
        sex: horsesEdit?.sex,
        hipNumber: horsesEdit?.hipNumber,
        consignor: horsesEdit?.consignor,
        price: formatNumber(horsesEdit?.price),
        eventId: horsesEdit?.event?.id,
        isArchived: horsesEdit?.isArchived,
        equibaseRegistrationNumber: horsesEdit?.equibaseRegistrationNumber,
        sireEquibaseRegistrationNumber: horsesEdit?.sireEquibaseRegistrationNumber,
        damEquibaseRegistrationNumber: horsesEdit?.damEquibaseRegistrationNumber,
    });

    const addSireInfo = (regID, name, date,) => {
        setEditHorses({ ...editHorses, sireName: name });
    };

    const addDamInfo = (regID, name, date,) => {
        setEditHorses({ ...editHorses, damName: name });
    };

    const updateHorses = (e) => {
        const updatedValue = e.target.name === 'price' ? formatNumber(e.target.value) : e.target.value;
        setEditHorses({ ...editHorses, [e.target.name]: updatedValue });
    };

    const updateNote = (e) => {
        setNote({ ...note, [e.target.name]: e.target.value });
    };

    const handleNoteClose = () => setNoteShow(false);
    const handleNoteShow = () => setNoteShow(true);

    const geHorses = async () => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const getRequestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
        const horsesResult = await horsesGet.json();
        //console.log('horsesResult', horsesResult.successData);
        setEditHorses({
            ...editHorses,
            id: horsesResult.successData?.id,
            sireName: horsesResult.successData?.sireName,
            damName: horsesResult.successData?.damName,
            name: horsesResult.successData?.name,
            sex: horsesResult.successData?.sex,
            hipNumber: horsesResult.successData?.hipNumber,
            consignor: horsesResult.successData?.consignor,
            price: formatNumber(horsesResult.successData?.price),
            equibaseRegistrationNumber: horsesResult.successData?.equibaseRegistrationNumber,
            sireEquibaseRegistrationNumber: horsesResult.successData?.sireEquibaseRegistrationNumber,
            damEquibaseRegistrationNumber: horsesResult.successData?.damEquibaseRegistrationNumber,
        });
    }

    useEffect(() => {
        // geHorses();
    }, [])


    async function SubmitHandler(e) {
        e.preventDefault();

        if (note.note == '') {
            setNoteError(true);
        } else {
            setNoteError(false);

            setLoading(true);
            const auth = JSON.parse(localStorage.getItem("auth"));
            const userId = auth.userId;

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${auth.token}`);

            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    ...note,
                    createdBy: userId,
                }),
                headers: myHeaders,
                redirect: 'follow'
            };

            const create = await fetch(`${API_URL}/Note/create`, requestOptions);
            const result = await create.json();
            // console.log('result', result);

            if (result.isSuccess == true) {
                setNote({ ...note, note: "" });
                handleNoteClose();
                setLoading(false);
                // onClose();

                Swal.fire({
                    icon: 'success',
                    title: 'Note Has Been Added Successfully!',
                })

                const getRequestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
                const horsesResult = await horsesGet.json();
                //console.log('horsesResult', horsesResult.successData);

                setHorsesEdit(horsesResult.successData);

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Note Has Not Been Added Successfully!',
                })
            }
            setLoading(false);
        }
    }


    const validate = (values) => {
        let errors = {};

        if (!values.hipNumber) {
            errors.hipNumber = "Hip Number Field Is Required!";
        }

        if (!values.name) {
            errors.name = "Name Field Is Required!";
        }

        // if (!values.price) {
        //     errors.price = "Price Field Is Required!";
        // }

        // if (!values.consignor) {
        //     errors.consignor = "Consignor Field Is Required!";
        // }

        return errors;
    };

    async function SubmitHorsesEdit(e) {
        e.preventDefault();
        setLoadingSave(true);

        // const newErroe = validate(horsesEdit);
        const auth = JSON.parse(localStorage.getItem("auth"));
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify({
                id: editHorses.id,
                sireName: editHorses?.sireName,
                damName: editHorses?.damName,
                name: editHorses.name,
                sex: editHorses.sex,
                hipNumber: editHorses.hipNumber,
                consignor: editHorses.consignor,
                price: editHorses.price,
                damEquibaseRegistrationNumber: editHorses.damEquibaseRegistrationNumber,
                sireEquibaseRegistrationNumber: editHorses.sireEquibaseRegistrationNumber,
                equibaseRegistrationNumber: editHorses.equibaseRegistrationNumber,
            }),
            headers: myHeaders,
            redirect: 'follow'
        };

        const create = await fetch(`${API_URL}/Horse/edit/${horsesEdit.id}`, requestOptions);
        const result = await create.json();
        //console.log('result', result);

        if (result.isSuccess == true) {
            Swal.fire({
                icon: 'success',
                title: 'Horse Has Been Updated Successfully!',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    setHorsesEdit({ ...horsesEdit, ...editHorses });
                    onClose(false, editHorses);
                }
            })
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Horse Has Been Not Updated Successfully!',
            })
        }
        setLoadingSave(false);
    }

    const onDamFilterChange = (e) => {
        setDamInput({ ...damInput, name: e });
        setEditHorses({ ...editHorses, damName: e });
    };
    const onSireFilterChange = (e) => {
        setSireInput({ ...sireInput, name: e });
        setEditHorses({ ...editHorses, sireName: e });
    };
    const NoteDelHandler = async (id) => {
        Swal.fire({
            title: 'Are You Sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete It!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const auth = JSON.parse(localStorage.getItem("auth"));
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append("Authorization", `Bearer ${auth.token}`);

                const requestOptions = {
                    method: 'DELETE',
                    body: JSON.stringify({
                        ...note,
                    }),
                    headers: myHeaders,
                    redirect: 'follow'
                };

                const delNote = await fetch(`${API_URL}/Note/delete/${id}`, requestOptions);
                const DelResult = await delNote.json();
                //console.log('DelResult', DelResult);

                if (DelResult.isSuccess == true) {
                    setNote({ ...note, note: "" });
                    Swal.fire({
                        icon: 'success',
                        title: 'Note Has Been Deleted Successfully!',
                    })

                    const getRequestOptions = {
                        method: 'GET',
                        headers: myHeaders,
                        redirect: 'follow'
                    };

                    const horsesGet = await fetch(`${API_URL}/Horse/byid/${horsesEdit.id}`, getRequestOptions);
                    const horsesResult = await horsesGet.json();
                    //console.log('horsesResult', horsesResult.successData);
                    setHorsesEdit(horsesResult.successData);
                    setEditHorses({
                        ...editHorses,
                        id: horsesResult.successData?.id,
                        sireName: horsesResult.successData?.sireName,
                        damName: horsesResult.successData?.damName,
                        name: horsesResult.successData?.name,
                        hipNumber: horsesResult.successData?.hipNumber,
                        consignor: horsesResult.successData?.consignor,
                        price: formatNumber(horsesResult.successData?.price),
                        equibaseRegistrationNumber: horsesResult.successData?.equibaseRegistrationNumber,
                        sireEquibaseRegistrationNumber: horsesResult.successData?.sireEquibaseRegistrationNumber,
                        damEquibaseRegistrationNumber: horsesResult.successData?.damEquibaseRegistrationNumber,
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Note Has Not Been Deleting Successfully!',
                    })
                }
            }
        })

        setLoading(false);
        setLoadingSave(false);
    }

    const onSireClear = (e) => {
        console.log('onSireClear', e);
        setSireOptions([]);
    }

    const onDamClear = (e) => {
        setDamOptions([]);
    }
    return (
        <div className='modal__wrapper'>
            <div className='modal__card' style={{ width: '50vw' }}>

                <div className='modal__head d-flex'>
                    <div class="h3 float-left">Horse Information</div>
                    <span onClick={onClose} class="float-right"><i className="fas fa-times"></i></span>
                </div>

                <div className='row mt-3 modal__form' ref={lenRef}>
                    <div className='col-md-6 mb-3'>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>Sire</label>
                            <ConfigProvider theme={{ token: { fontSize: 'large' } }}>
                                <BreedHorseTypeAhead
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    className="table_search_input form-control rounded input__field flex-md-grow-3"
                                    name="name"
                                    value={editHorses.sireName}
                                    onChange={onSireFilterChange}
                                    onClear={onSireClear}
                                    options={sireOptions}
                                    setOptions={setSireOptions}
                                    autoComplete="off"
                                    placeHolder="Search Sire "
                                    optionLabelProp="horseName"
                                    variant="borderless"
                                    cfg={sireSearchConfig}
                                    disabled={horsesEdit.isSireCpuSelected}
                                    optionRender={(item) => (
                                        <BreedHorseSearchResultRender
                                            item={item}
                                            onClick={() => addSireInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                        />
                                    )}
                                />
                            </ConfigProvider>
                        </div>
                    </div>

                    <div className='col-md-6 mb-3' ref={lenRef}>
                        <div className='form-group text-start position-relative'>
                            <label className='mb-2'>Dam</label>
                            <ConfigProvider theme={{ token: { fontSize: 'large' } }}>
                                <BreedHorseTypeAhead
                                    className="table_search_input form-control rounded input__field flex-md-grow-3"
                                    name="name"
                                    value={editHorses.damName}
                                    searchValue={editHorses.damName}
                                    onChange={onDamFilterChange}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    onClear={onDamClear}
                                    options={damOptions}
                                    setOptions={setDamOptions}
                                    autoComplete="off"
                                    placeHolder="Search Dam "
                                    optionLabelProp="horseName"
                                    variant="borderless"
                                    cfg={damSearchConfig}
                                    disabled={horsesEdit.isDamCpuSelected}
                                    optionRender={(item) => (
                                        <BreedHorseSearchResultRender
                                            item={item}
                                            onClick={() => addDamInfo(item.equibaseRegistrationNumber, item.horseName, item.foalingDate)}
                                        />
                                    )}
                                />
                            </ConfigProvider>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Sire Reg. Number</label>
                            <input type="text" className='form-control'
                                name='sireEquibaseRegistrationNumber'
                                value={editHorses.sireEquibaseRegistrationNumber}
                                disabled={horsesEdit.isSireCpuSelected}
                                onChange={updateHorses}
                                placeHolder='Sire Registration Number' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Dam Reg. Number</label>
                            <input type="text" className='form-control'
                                name='damEquibaseRegistrationNumber'
                                value={editHorses.damEquibaseRegistrationNumber}
                                disabled={horsesEdit.isDamCpuSelected}
                                onChange={updateHorses}
                                placeHolder='Dam Registration Number' />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Hip Number</label>
                            <input type="text" className='form-control'
                                name='hipNumber'
                                value={editHorses.hipNumber}
                                onChange={updateHorses}
                                placeHolder='Hip Number' />
                        </div>
                        {/* {errors?.hipNumber?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.hipNumber}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 mt-2 text-start'>
                            <label className='mb-2'>* Sex</label>
                            <select className='form-control'
                                name='sex'
                                value={editHorses.sex}
                                onChange={updateHorses}
                            >
                                <option>Select</option>
                                <option value={'C'} selected={horsesEdit.sex == 'C' ? true : false}>Colt</option>
                                <option value={'F'} selected={horsesEdit.sex == 'F' ? true : false}>Filly</option>
                                <option value={'G'} selected={horsesEdit.sex == 'G' ? true : false}>Gelding</option>
                                <option value={'HH'} selected={horsesEdit.sex == 'HH' ? true : false}>Horse</option>
                                <option value={'M'} selected={horsesEdit.sex == 'M' ? true : false}>Mare</option>
                                <option value={'S'} selected={horsesEdit.sex == 'S' ? true : false}>Stallion</option>
                                {/* HH - Horse, G - Gelding, F - Filly, M - Mare, S - Stallion, C - Colt */}
                            </select>
                        </div>
                        {errors?.sex?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.sex}</p>
                        ) : null}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Horse Name</label>
                            <input type="text" className='form-control'
                                name='name'
                                value={editHorses.name}
                                onChange={updateHorses}
                                placeHolder='New Horse Name' />
                        </div>
                        {/* {errors?.name?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.name}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Price</label>
                            <input type="text" className='form-control'
                                name='price'
                                value={editHorses.price}
                                onChange={updateHorses}
                                placeHolder='Price' />
                        </div>
                        {/* {errors?.price?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.price}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Equibase Reg. Number</label>
                            <input type="text" className='form-control'
                                name='equibaseRegistrationNumber'
                                value={editHorses?.equibaseRegistrationNumber}
                                onChange={updateHorses}
                                placeHolder='Equibase Registration Number' />
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <div className='form-group mb-3 text-start'>
                            <label className='mb-2' style={{
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>Consignor</label>
                            <input type="text" className='form-control'
                                name='consignor'
                                value={editHorses.consignor}
                                onChange={updateHorses}
                                placeHolder='Consignor' />
                        </div>
                        {/* {errors?.consignor?.length > 0 ? (
                            <p className="text-danger" style={{ marginTop: '-10px' }}>{errors.consignor}</p>
                        ) : null} */}
                    </div>

                    <div className='col-md-12'>
                    </div>

                    <div className='col-md-12  text-start'>
                        <h4 className='label__2'>Notes</h4>
                    </div>


                    <div className='col-md-12 scrollbar overflow-auto' style={{ maxHeight: '300px' }}>
                        <div className='row w-100'>
                            {
                                horsesEdit.horseNotes == '' ? null : <>
                                    {
                                        horsesEdit.horseNotes.map((item, index) => (
                                            <div key={index} className='col-md-12  p-2 mt-2 mb-2'>
                                                <h4 className='label__1 w-100 d-flex justify-content-between'>
                                                    <span>{moment(item.createdAt).format("DD MMMM YYYY")}</span>
                                                    <i className="fas fa-trash text-danger" onClick={() => NoteDelHandler(item.id)} style={{ cursor: 'pointer' }}></i>
                                                </h4>
                                                <p className='pera__1 w-100 text-start'>{item.note}</p>
                                                <p className='w-100 mt-1 border-bottom'></p>
                                            </div>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div>

                    <div className='col-md-12 mt-3 mb-3 text-start'>
                        <textarea className='form-control textarea' placeHolder='Write here' rows={3} name="note" value={note.note} onChange={updateNote} required disabled={horsesEdit?.id == '' ? true : false}></textarea>

                        {
                            noteErroer ? <div className="text-danger mt-2 text-start">
                                This field is required
                            </div> : null
                        }
                    </div>

                    <div className='col-md-12 mb-2 d-flex justify-content-start'>
                        {
                            loading ? <button className="btn btn-primary">
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button> : <button
                                className='btn btn-primary site-s-font'
                                onClick={SubmitHandler}
                                disabled={horsesEdit?.id == '' ? true : false}
                            >
                                <i className="fas fa-plus-circle"></i> Add Note
                            </button>
                        }
                    </div>

                    <div className='modal__footer'>
                        <div className='col-md-12 d-flex justify-content-end gap-2'>
                            <button className='btn btn-defaut border site-s-font' onClick={() => onClose(true)}>Cancel</button>
                            {
                                loadingSave ? <button className="btn btn-primary">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </button> : <button
                                    className='btn btn-primary site-s-font'
                                    onClick={SubmitHorsesEdit}
                                    style={{
                                        // backgroundColor: 'rgba(84, 124, 255, 1)'
                                    }}
                                >Save</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Modal